import { useRecoilValue } from "recoil";
import { Controller, useController, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { configAtom } from "../../../../../store/common/configStore";
import {
  DayInfoType,
  parentListType,
} from "../../../../../types/requestedCourse/requestedCourseTypes";
import {
  dayToNumber,
  maskMobileNumber,
  minutesToTimeString,
  numberToDay,
  union,
} from "../../../../../util/util";
import SelectDropDown from "../../../../common/select/SelectDropDown";
import AddressSearch from "../../../../common/modal/AdressSearchModal";
import CommonCheckChip from "../../../../common/form/CommonCheckChip";
import BottomSheetModal from "../../../../common/modal/BottomSheetModal";
import CommonWeekButton from "../../../../common/form/CommonWeekButton";
import CommonDatePicker from "../../../../common/datePicker/CommonDatePicker";
import ParentListContainer from "./ParentListContainer";
import { userAtom } from "../../../../../store/common/userStore";
import useAddCourseRequestMutation from "../../../../../hooks/requestedCourse/useAddCourseRequestMutation";
import CommonSearchForm from "../../../../common/form/CommonSearchForm";
import SlideTimePicker from "../../../../common/datePicker/SlideTimePicker";
import CommonModal from "../../../../common/modal/CommonModal";
import { useFormDirtyState } from "../../../../../hooks/requestedCourse/useFormDirtyState";

function AddUpdateCourseForm() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const user = useRecoilValue(userAtom);
  const [modalName, setModalName] = useState<string>("");
  const [directInput, setDirectInput] = useState("직접입력");
  const [parentList, setParentList] = useState<parentListType[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [timeData, setTimeData] = useState<DayInfoType[]>([]);
  const [isAllFieldsValid, setIsAllFieldsValid] = useState(false);
  const addCourseRequestMutation = useAddCourseRequestMutation(setModalName);
  const [requestPartnerInfo, setRequestPartnerInfo] = useState({
    id: 0,
    name: "",
    mobile: "",
  });
  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm();

  useFormDirtyState({
    watch,
    fieldsToWatch: [
      "location",
      "className",
      "times",
      "hour",
      "startDate",
      "class_week",
    ],
    dependencies: [
      { value: parentList.length, name: "parentList" },
      { value: timeData.length, name: "timeData" },
      { value: selectedDays.length, name: "selectedDays" },
      { value: requestPartnerInfo.id, name: "requestPartnerInfo" },
    ],
  });

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const handleAnimationComplete = () => {
    if (!isOpen) {
      setModalName("");
    }
  };

  const watchCourseTimes = watch("times");
  const watchStartDate = watch("startDate");
  const watchWeek = watch("class_week");
  const courseHour = watch("hour");
  const config = useRecoilValue(configAtom);

  const { field: subjectsField } = useController({
    name: "className",
    control,
    defaultValue: [],
  });

  const { field: weekField } = useController({
    name: "class_week",
    control,
    defaultValue: [],
  });

  const handleFieldClick = (fieldName: string, value: string) => {
    const currentValue =
      fieldName === "className" ? subjectsField.value : weekField.value;
    const newValue = currentValue.includes(value)
      ? currentValue.filter((item: string) => item !== value)
      : [...currentValue, value];

    if (fieldName === "className") {
      subjectsField.onChange(newValue);
    } else {
      weekField.onChange(newValue);
      setSelectedDays(newValue);

      // 새로운 요일이 추가된 경우
      if (!currentValue.includes(value)) {
        setTimeData((prevTimeData) => {
          // 이미 설정된 시간이 있는 요일 찾기
          const existingTimeSlot = prevTimeData.find(
            (item) => item.class_start_time && item.class_end_time,
          );

          // 기본 시간값 설정
          const defaultStartTime = dayjs().hour(18).minute(0);
          const durationInMinutes = watch("hour") || 120;

          // 새로운 요일의 시간 데이터 생성
          const newDayData = {
            class_week: value,
            class_start_time:
              existingTimeSlot?.class_start_time || defaultStartTime,
            class_end_time:
              existingTimeSlot?.class_end_time ||
              defaultStartTime.add(durationInMinutes, "minute"),
          };

          // 기존 요일은 유지하고 새로운 요일 추가
          return [
            ...prevTimeData.filter((item) => item.class_week !== value),
            newDayData,
          ];
        });
      } else {
        // 요일이 제거된 경우 해당 요일의 시간 데이터도 제거
        setTimeData((prevTimeData) =>
          prevTimeData.filter((item) => newValue.includes(item.class_week)),
        );
      }
    }
  };

  const handleTimeChange = (
    classWeek: string,
    type: "class_start_time" | "class_end_time",
    value: Dayjs | null,
  ) => {
    const durationInMinutes = watch("hour");

    setTimeData((prev: DayInfoType[]) => {
      return prev.map((dayData) => {
        if (dayData.class_week === classWeek) {
          const updatedDayData = {
            ...dayData,
            [type]: value,
          };
          if (type === "class_start_time" && value) {
            updatedDayData.class_end_time = value.add(
              durationInMinutes,
              "minute",
            );
          }
          return updatedDayData;
        }
        return dayData;
      });
    });
  };

  const onClickDirectInput = () => {
    // directInput값이 "직접입력" 초기값이면 모달만 열고 값을 전달 하지 않고
    // directInput값이 직접입력이 아닌 모달로 입력된 값이 있으면 handleFieldClick으로 값을 전달한다
    if (directInput === "직접입력") {
      setModalName("directInput");
    } else {
      setModalName("directInput");
      handleFieldClick("className", directInput);
    }
  };

  const weekOrder = ["월", "화", "수", "목", "금", "토", "일"];

  const mergedArray = union([...weekField.value], [...selectedDays]).sort(
    (a, b) => weekOrder.indexOf(a) - weekOrder.indexOf(b),
  );

  useEffect(() => {
    if (watchCourseTimes && watchStartDate && watchWeek.length) {
      const startDate = dayjs(watchStartDate);
      let lessonsLeft = parseInt(watchCourseTimes, 10);
      let currentDate = startDate;

      while (lessonsLeft > 0) {
        // dayjs의 day()가 0(일)~6(토)를 반환하므로 1~7로 변환
        const currentDay = currentDate.day() === 0 ? 7 : currentDate.day();
        // 현재 날짜의 요일이 watchWeek에 포함되어 있다면, lessonsLeft를 줄인다.
        if (watchWeek.includes(numberToDay(currentDay))) {
          lessonsLeft--;
        }
        // lessonsLeft가 남아있다면 다음 날로 이동한다.
        if (lessonsLeft > 0) {
          currentDate = currentDate.add(1, "day");
        }
      }
      setValue("endDate", currentDate);
    } else {
      setValue("endDate", null);
    }
  }, [watchCourseTimes, watchStartDate, watchWeek, setValue]);

  // class_start_time 설정 시 class_end_time 자동 설정
  useEffect(() => {
    const durationInMinutes = watch("hour");

    setTimeData((prev: DayInfoType[]) =>
      prev.map((dayData) => {
        if (dayData.class_start_time) {
          return {
            ...dayData,
            class_end_time: dayData.class_start_time.add(
              durationInMinutes,
              "minute",
            ),
          };
        }
        return dayData;
      }),
    );
  }, [courseHour, watch]);

  // 폼 필수 값 체크
  useEffect(() => {
    const allTimeDataValid = timeData.every(
      (time) => time.class_start_time && time.class_end_time,
    );
    const areCourseInfoParentsValid = parentList.length > 0;
    const isPartnerInfoValid =
      requestPartnerInfo.id !== 0 &&
      requestPartnerInfo.name !== "" &&
      requestPartnerInfo.mobile !== "";

    setIsAllFieldsValid(
      allTimeDataValid &&
        areCourseInfoParentsValid &&
        isPartnerInfoValid &&
        isValid,
    );
  }, [timeData, parentList, requestPartnerInfo, isValid]);

  const handleSubmitConfirm = () => {
    const formData = getValues();
    const startDate = formData.startDate ? formData.startDate.toDate() : null;
    const endDate = formData.endDate ? formData.endDate.toDate() : null;

    const dayInfoJson = JSON.stringify({
      data: timeData
        .map((item) => ({
          day: dayToNumber(item.class_week),
          start: item.class_start_time
            ? item.class_start_time.format("HH:mm")
            : null,
          end: item.class_end_time ? item.class_end_time.format("HH:mm") : null,
        }))
        .sort(
          (a, b) =>
            weekOrder.indexOf(numberToDay(a.day)) -
            weekOrder.indexOf(numberToDay(b.day)),
        ),
    });

    const requestData = {
      location: formData.location,
      className: formData.className.join(","),
      dayInfoJson,
      times: formData.times,
      hour: formData.hour,
      totalHour: formData.times * formData.hour,
      startDate: dayjs(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: dayjs(endDate).format("YYYY-MM-DD HH:mm:ss"),
      status: "ONGOING",
      shareStatus: "REQUEST",
      requestDate: new Date(),
      responseDate: "",
      tutorCode: String(requestPartnerInfo.id),
      createdMemberType: user.member.memberType,
      businessCode: String(user.member.id),
      courseInfoParents: parentList,
    };

    addCourseRequestMutation.mutate(requestData);
  };

  return (
    <main className="content pb12">
      <div className="section pb6">
        <form
          onSubmit={handleSubmit(() => {
            setModalName("submit");
          })}
        >
          <div className="flex-wrap">
            <span className="flex-wrap__title">
              파트너 선생님 <em className="color-red">*</em>
            </span>
            <div
              className="field-group__cont"
              role={"presentation"}
              onClick={() => {
                setModalName("tutorSearch");
                setIsOpen(true);
              }}
            >
              <i className="icon i-search" />
              <input
                type="text"
                value={
                  requestPartnerInfo.name && requestPartnerInfo.mobile
                    ? `${requestPartnerInfo.name} ${maskMobileNumber(requestPartnerInfo.mobile)}`
                    : ""
                }
                readOnly
                className="field-group__input"
                placeholder="이름 검색"
              />
            </div>
          </div>

          {/* 학부모 연락처 */}
          <ParentListContainer
            parentList={parentList}
            setParentList={setParentList}
          />

          <div className="flex-wrap pb6">
            <span className="flex-wrap__title">
              지역 <em className="color-red">*</em>
            </span>
            <div className="flex-wrap__cont">
              <div className="field-group">
                <label className="field-group__label field-group__label--blind">
                  지역
                </label>
                <Controller
                  name="location"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <AddressSearch
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="flex-wrap pb6">
            <span className="flex-wrap__title">
              과목 <em className="color-red">*</em>
            </span>
            <div className="flex-wrap__cont">
              <Controller
                name="className"
                control={control}
                rules={{ required: true }}
                render={() => (
                  <div className="check-chip">
                    {["국어", "수학", "영어", "사탐", "과탐"].map(
                      (subject: string, index: number) => (
                        <CommonCheckChip
                          key={index}
                          onClick={() => handleFieldClick("className", subject)}
                          subject={subject}
                          isSelected={subjectsField.value.includes(subject)}
                          chipType="checkbox"
                        />
                      ),
                    )}
                    <CommonCheckChip
                      subject={directInput}
                      onClick={onClickDirectInput}
                      isSelected={subjectsField.value.includes(directInput)}
                      chipType="checkbox"
                      isDirectButton
                    />
                  </div>
                )}
              />
            </div>
            {modalName === "directInput" && (
              <BottomSheetModal
                setDirectInput={(value) => {
                  setDirectInput(value);
                  handleFieldClick("className", value);
                  setModalName("");
                }}
                title={"직접입력"}
                placeHolder={["과목입력"]}
                textLimit={12}
                modalName={modalName}
                setModalName={setModalName}
                inputKeys={["subject"]}
              />
            )}
          </div>
          <div className="divider" />
          <div className="flex-wrap flex-wrap--direction-row">
            <div className="flex-wrap flex-wrap--grow-1">
              <span className="flex-wrap__title">
                수업 횟수 <em className="color-red">*</em>
              </span>
              <div className="flex-wrap__cont">
                <Controller
                  name="times"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectDropDown
                      isFull
                      value={field.value}
                      onChange={field.onChange}
                      initialLabel={"선택"}
                      options={config?.classIndex}
                    />
                  )}
                />
              </div>
            </div>

            <div className="flex-wrap flex-wrap--grow-1">
              <span className="flex-wrap__title">
                회당 수업 시간 <em className="color-red">*</em>
              </span>
              <div className="flex-wrap__cont">
                <Controller
                  name="hour"
                  control={control}
                  defaultValue={""}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <SelectDropDown
                      isFull
                      value={field.value}
                      onChange={field.onChange}
                      initialLabel={"선택"}
                      options={config?.classTime}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="flex-wrap pb6">
            <span className="flex-wrap__title">총 수업 시간</span>
            <div className="flex-wrap__cont">
              <div className="field-group">
                <label
                  htmlFor="total-time"
                  className="field-group__label field-group__label--blind"
                >
                  총 수업 시간
                </label>
                <div className="field-group__cont field-group__cont--disabled">
                  <input
                    className="field-group__input"
                    type="text"
                    value={minutesToTimeString(watch("times") * watch("hour"))}
                    disabled
                    {...register("course_total_hour")}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="divider" />
          <div className="flex-wrap">
            <span className="flex-wrap__title">
              요일 및 시간 <em className="color-red">*</em>
            </span>
            <div className="flex-wrap__cont">
              <div className="week-time">
                <div className="week-time__nav">
                  <Controller
                    name="class_week"
                    control={control}
                    rules={{ required: true }}
                    render={() => (
                      <>
                        {["월", "화", "수", "목", "금", "토", "일"].map(
                          (week: string, index: number) => (
                            <CommonWeekButton
                              key={index}
                              isSelected={weekField.value.includes(week)}
                              onClick={() =>
                                handleFieldClick("class_week", week)
                              }
                              week={week}
                            />
                          ),
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>

          {mergedArray.map((classWeek, index) => (
            <div className="week-time__item" key={index}>
              <div className="week-time__title">{classWeek}</div>
              <div className="week-time__field">
                <label className="week-time__label">시작 시간</label>
                <SlideTimePicker
                  value={
                    timeData.find((item) => item.class_week === classWeek)
                      ?.class_start_time || null
                  }
                  onChange={(value) =>
                    handleTimeChange(classWeek, "class_start_time", value)
                  }
                  width={100}
                />
                <em>~</em>
                <label className="week-time__label">종료 시간</label>
                <SlideTimePicker
                  disabled
                  value={
                    timeData.find((item) => item.class_week === classWeek)
                      ?.class_end_time || null
                  }
                  onChange={(value) =>
                    handleTimeChange(classWeek, "class_end_time", value)
                  }
                  width={100}
                />
              </div>
            </div>
          ))}
          <div className="flex-wrap">
            <span className="flex-wrap__title">
              시작일 <em className="color-red">*</em>
            </span>
            <Controller
              name="startDate"
              control={control}
              defaultValue={null}
              rules={{ required: true }}
              render={({ field }) => (
                <CommonDatePicker
                  value={field.value || null}
                  onChange={field.onChange}
                  width={100}
                />
              )}
            />
          </div>
          <div className="flex-wrap">
            <span className="flex-wrap__title">예상 종료일</span>
            <Controller
              name="endDate"
              control={control}
              defaultValue={null}
              render={({ field }) => (
                <CommonDatePicker
                  disabled
                  value={field.value || null}
                  onChange={field.onChange}
                  width={100}
                />
              )}
            />
          </div>

          <div className="bottom-button-wrap py10">
            <button
              type="button"
              className="btn btn--full btn--gray"
              onClick={() => {
                navigate(-1);
              }}
            >
              취소
            </button>
            <button
              disabled={!isAllFieldsValid}
              type="submit"
              className={`btn btn--full ${!isAllFieldsValid ? "is-disabled" : "btn--primary"}`}
            >
              요청하기
            </button>
          </div>
        </form>
      </div>

      {modalName === "tutorSearch" && (
        <div className="bottom-sheet">
          <motion.div
            className="bottom-sheet__container"
            initial={{ y: "100%" }}
            animate={{ y: isOpen ? "0%" : "100%" }}
            transition={{ type: "spring", stiffness: 250, damping: 30 }}
            onAnimationComplete={handleAnimationComplete}
          >
            <div
              style={{
                opacity: 1,
                height: "50vh", // 화면의 절반 높이 차지
                maxHeight: "50vh", // 최대 높이 50%
                overflow: "hidden", // 내용이 넘칠 경우 숨김
              }}
            >
              <CommonSearchForm
                closeForm={() => {
                  setIsOpen(false);
                }}
                setValue={setRequestPartnerInfo}
                searchType={"C"}
              />
            </div>
          </motion.div>
        </div>
      )}
      <CommonModal
        open={modalName === "submit"}
        modalClose={() => {
          setModalName("");
        }}
        title={"과외 요청"}
        text={
          "요청 후 수정이 불가하며(선생님은 수정 가능), 선생님의 승인이 완료되면 과외가 생성됩니다. 시작일 전날까지 미승인 시, 요청은 자동 취소됩니다. 과외를 요청하시겠습니까?"
        }
        onConfirm={handleSubmitConfirm}
      />
    </main>
  );
}

export default AddUpdateCourseForm;
