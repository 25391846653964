import dayjs from "dayjs";
import { ConfigType } from "../types/config/configTypes";

export function timeStringToMinutes(timeString: string): number {
  const hoursMatch = timeString.match(/(\d+)시간/);
  const minutesMatch = timeString.match(/(\d+)분/);
  const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
  const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
  return hours * 60 + minutes;
}

export function minutesToTimeString(totalMinutes: number): string {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours ? `${hours}시간` : ""}${minutes ? ` ${minutes}분` : ""}`.trim();
}

export const findNextDay = (date: dayjs.Dayjs, targetDays: string[]) => {
  const dayIndex = targetDays.map((day) =>
    ["일", "월", "화", "수", "목", "금", "토"].indexOf(day),
  );
  let nextDate = dayjs(date);
  while (!dayIndex.includes(nextDate.day())) {
    nextDate = nextDate.add(1, "day");
  }
  return nextDate;
};

export const union = (arr1: any, arr2: any) =>
  Array.from(new Set([...arr1, ...arr2]));

export function convertTimeFormat(timeStr: string) {
  let [hour, minute] = timeStr.split(":").map(Number);
  const period = hour >= 12 ? "오후" : "오전";

  hour %= 12;
  hour = hour || 12;

  return `${period} ${hour}:${minute < 10 ? `0${minute}` : minute}`;
}

// 요일 숫자 변환

const weekDays = ["월", "화", "수", "목", "금", "토", "일"];

export const dayToNumber = (day: string) => weekDays.indexOf(day) + 1;
export const numberToDay = (num: number) => weekDays[num - 1];

// 휴대폰 번호 포맷
export const formatPhoneNumber = (input: string) => {
  const cleaned = input.replace(/\D/g, "");
  if (cleaned.length === 10) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }
  if (cleaned.length === 11) {
    return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 7)}-${cleaned.slice(7)}`;
  }
  return input;
};

// 빈 데이터인지 확인
export const isDataEmpty = (data: any) => {
  if (data === null || data === undefined) {
    return true;
  }
  if (Array.isArray(data)) {
    return data.length === 0;
  }
  if (typeof data === "object") {
    return Object.keys(data).length === 0;
  }
  return !data;
};

// 배열이 같은지 비교
export const areArraysEqual = (array1: string[], array2: string[]): boolean => {
  if (array1.length !== array2.length) return false;
  return array1.every((element) => array2.includes(element));
};

// 전화번호 중간번호 가리기
export const maskMobileNumber = (mobile: string | null | undefined) => {
  if (!mobile) return "";
  const sanitizedMobile = mobile.replace(/[^\d]/g, "");
  return sanitizedMobile.replace(/(\d{3})(\d{3,4})(\d{4})/, "$1-****-$3");
};

// 이메일 마스킹
export default function maskEmail(email: string | undefined) {
  if (!email) return;
  const [user, domain] = email.split("@"); // 이메일을 아이디와 도메인으로 분리
  if (user.length <= 2) {
    return `${user}@${domain}`; // 아이디가 너무 짧으면 마스킹하지 않음
  }

  // 아이디의 3번째 문자부터 마스킹
  const maskedUser = user.slice(0, 2) + "*".repeat(user.length - 2);
  return `${maskedUser}@${domain}`; // 마스킹된 아이디와 도메인을 결합
}

// 만 나이계산
export const calculateAge = (birthDateString: string) => {
  if (!birthDateString) return;

  // 'YYYYMMDD' 형식인 경우 '-'을 추가하여 'YYYY-MM-DD'로 변환
  const formattedDateString =
    birthDateString.length === 8
      ? `${birthDateString.slice(0, 4)}-${birthDateString.slice(4, 6)}-${birthDateString.slice(6)}`
      : birthDateString;

  const today = new Date();
  const birthDate = new Date(formattedDateString);

  let age = today.getFullYear() - birthDate.getFullYear();

  // 생일이 안 지났으면 나이를 한 살 줄임
  const monthDiff = today.getMonth() - birthDate.getMonth();
  const dayDiff = today.getDate() - birthDate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age;
};

// 컨피그 상수 변환
export function translateValue<T>(
  key: string,
  codeTable: ConfigType<T>,
): T | string {
  return codeTable[key] || key;
}

// 사업자번호 포맷팅 함수
export const formatBusinessNumber = (value: string | undefined) => {
  if (!value) return "";
  const cleanValue = value.replace(/\D+/g, ""); // 숫자만 남기기
  if (cleanValue.length <= 3) return cleanValue;
  if (cleanValue.length <= 5)
    return `${cleanValue.slice(0, 3)}-${cleanValue.slice(3)}`;
  return `${cleanValue.slice(0, 3)}-${cleanValue.slice(3, 5)}-${cleanValue.slice(5, 10)}`;
};

// 유튜브 id 추출
export const extractYoutubeId = (url: string) => {
  const regExp =
    /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};
