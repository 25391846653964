import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { RequestedCourseListType } from "../../types/requestedCourse/requestedCourseTypes";

class RequestedCourseApi extends AxiosApiWrapper {
  protected endPoint!: string;
  // 사업자 과외 요청 리스트
  public fetchRequestedCourseList(
    data: Record<string, unknown>,
  ): Promise<RequestedCourseListType> {
    this.endPoint = "api/v1/business/course-info/list-req";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 과외 요청 생성
  public fetchAddCourseRequest(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/course-info";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 과외 요청 취소
  public fetchCancelCourseRequest(data: Record<string, unknown>) {
    this.endPoint = "api/v1/business/course-info/share-status";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new RequestedCourseApi();
