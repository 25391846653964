import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import RequestedCourseListApi from "../../services/requestedCourse/RequestedCourseApi";

const useCancelCourseRequestMutation = (
  setModalName: React.Dispatch<React.SetStateAction<string>>,
) => {
  const navigate = useNavigate();

  return useMutation(
    (params: { id: string; memberCode: string }) => {
      return RequestedCourseListApi.fetchCancelCourseRequest({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        navigate("/requested-course");
        window.scrollTo(0, 0);
        enqueueSnackbar("과외 요청이 취소되었습니다.");
        setModalName("");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useCancelCourseRequestMutation;
