import { Controller, useFormContext } from "react-hook-form";
import { useState } from "react";
import CommonModalDialog from "../../../common/modal/CommonModalDialog";
import TermsOfServiceContainer from "../../myInfo/TermsOfServiceContainer";
import PrivacyPolicyContainer from "../../myInfo/PrivacyPolicyContainer";
interface CheckboxGroupProps {
  name: string;
  options: {
    label: string;
    value?: string;
    required: boolean;
    showModalType?: string;
  }[];
}

function CheckboxGroup({ name, options }: CheckboxGroupProps) {
  const [modalName, setModalName] = useState("");
  const { control, watch, setValue } = useFormContext();

  const watchAll = watch(options.map((option) => `${name}.${option.value}`));

  const handleAllCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    options.forEach((option) => {
      setValue(`${name}.${option.value}`, isChecked, {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
  };

  return (
    <div className="field-group">
      <div className="checkbox checkbox--pos-rel">
        <label className="checkbox__wrapper">
          <span className="checkbox__title checkbox__title--large">
            전체 동의
          </span>
          <input
            type="checkbox"
            className="checkbox--hidden checkbox--primary"
            checked={watchAll.every((val) => val === true)}
            onChange={handleAllCheck}
          />
          <div className="checkbox--show" />
        </label>
      </div>
      {options.map((option, index) => (
        <div className="checkbox checkbox--pos-rel" key={index}>
          <Controller
            name={`${name}.${option.value}`}
            control={control}
            defaultValue={false}
            rules={{ required: option.required }}
            render={({ field }) => (
              <>
                <label className="checkbox__wrapper">
                  <span className="checkbox__title">{option.label}</span>
                  <input
                    type="checkbox"
                    className="checkbox--hidden checkbox--primary"
                    checked={field.value}
                    onChange={field.onChange}
                  />
                  <div className="checkbox--show" />
                </label>
                {option.showModalType && (
                  <button
                    type="button"
                    className="checkbox__link"
                    onClick={() => {
                      setModalName(option?.showModalType || "");
                    }}
                  >
                    내용보기
                  </button>
                )}
              </>
            )}
          />
        </div>
      ))}

      {modalName === "terms" && (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <TermsOfServiceContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      )}

      {modalName === "privacy" && (
        <CommonModalDialog setModalName={setModalName}>
          {({ clickClose }) => (
            <PrivacyPolicyContainer clickClose={clickClose} />
          )}
        </CommonModalDialog>
      )}
    </div>
  );
}

export default CheckboxGroup;
