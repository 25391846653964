import { useRecoilValue } from "recoil";
import AlarmListCard from "./AlarmListCard";
import useAlarmListQuery from "../../../../hooks/home/alarm/useAlarmListQuery";
import { userAtom } from "../../../../store/common/userStore";
function AlarmListContainer() {
  const user = useRecoilValue(userAtom);
  const { data: alarmListData } = useAlarmListQuery({
    memberCode: String(user.member.id),
  });

  return (
    <main className="content">
      {alarmListData?.items && alarmListData?.items.length > 0 ? (
        <div className="board-list2">
          <ul className="board-list2__group">
            {alarmListData?.items.map((item, index) => (
              <AlarmListCard item={item} key={`alarm-list-${index}`} />
            ))}
          </ul>
        </div>
      ) : (
        <div className="page-empty">아직 새로운 알림이 없어요.</div>
      )}
    </main>
  );
}

export default AlarmListContainer;
