import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { SearchEmailType } from "../../types/auth/authTypes";

class AuthApi extends AxiosApiWrapper {
  protected endPoint!: string;

  // 휴대폰 인증 번호 전송(비밀번호 찾기)
  public fetchSendMobileAuthCode(data: Record<string, unknown>) {
    this.endPoint = "api/v1/auth/cert-number/request";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 휴대폰 인증 번호 전송(비밀번호 찾기) - 이메일 찾기 화면 / 가입되어있는 휴대폰인지 확인까지 하는 api
  public fetchSendMobileMemberAuthCode(data: Record<string, unknown>) {
    this.endPoint = "api/v1/auth/cert-number/request-member";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 이메일로 인증 번호 전송(비밀번호 찾기)
  public fetchSendEmailAuthCode(data: Record<string, unknown>) {
    this.endPoint = "api/v1/auth/password/search";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 이메일로 인증 번호 검증(비밀번호 찾기)
  public fetchConfirmEmailAuthCode(data: Record<string, unknown>) {
    this.endPoint = "api/v1/auth/cert-number/confirm";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 이메일 찾기
  public fetchEmailSearch(
    data: Record<string, unknown>,
  ): Promise<SearchEmailType> {
    this.endPoint = "api/v1/auth/email/search";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 비밀번호 수정
  public fetchUpdatePassword(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/password";
    try {
      return this.put(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 비밀번호 확인
  public fetchCheckPassword(data: Record<string, unknown>) {
    this.endPoint = "api/v1/member/password";
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new AuthApi();
