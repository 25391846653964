import { atom } from "recoil";
import { PartnerTutorType } from "../../types/partnerTutor/partnerTutorTypes";

export const selectedPartnerInfoAtom = atom<
  PartnerTutorType & { copyProfileId?: number }
>({
  key: "selectedPartnerInfo",
  default: {
    id: 0,
    businessCode: 0,
    tutorCode: 0,
    tutorName: "",
    birthDate: "",
    tutorEmail: "",
    tutorMobile: "",
    ageGroup: 0,
    profileImage: "",
    createdAt: "",
    mappingId: 0,
    memo: "",
    profile: false,
    interest: false,
    publicFlag: "",
    copyProfileId: undefined, // copyProfileId 필드를 추가
  },
});
