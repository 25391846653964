import moment from "moment";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import CommonModal from "../../common/modal/CommonModal";
import useRequestedCourseInfoQuery from "../../../hooks/requestedCourse/useRequestedCourseInfoQuery";
import {
  convertTimeFormat,
  maskMobileNumber,
  minutesToTimeString,
  numberToDay,
} from "../../../util/util";
import useCancelCourseRequestMutation from "../../../hooks/requestedCourse/useCancelCourseRequestMutation";
import { userAtom } from "../../../store/common/userStore";

function RequestedCourseInfoContainer() {
  const location = useLocation();
  const { data: requestedCourseInfo } = useRequestedCourseInfoQuery(
    String(location.state),
  );
  const user = useRecoilValue(userAtom);
  const [modalName, setModalName] = useState<string>("");
  const modalClose = () => {
    setModalName("");
  };
  const courseCancelMutation = useCancelCourseRequestMutation(setModalName);

  let dayInfo = [];
  try {
    if (requestedCourseInfo?.courseInfo?.dayInfoJson) {
      dayInfo =
        JSON.parse(requestedCourseInfo.courseInfo.dayInfoJson)?.data || [];
    }
  } catch (error) {
    console.error("Invalid JSON in dayInfoJson", error);
  }

  function getStatusInfo(status: string | undefined) {
    switch (status) {
      case "ACCEPT":
        return { text: "승인 완료", className: "complete" };
      case "REQUEST":
        return { text: "승인 대기", className: "waiting" };
      case "REJECT":
        return { text: "승인 거절", className: "refuse" };
      case "EXPIRE":
        return { text: "기간 만료", className: "refuse" };
      case "CANCEL":
        return { text: "요청 취소", className: "refuse" };
      default:
        return null;
    }
  }

  const statusInfo = getStatusInfo(requestedCourseInfo?.courseInfo.shareStatus);

  const handleConfirm = () => {
    courseCancelMutation.mutate({
      id: String(requestedCourseInfo?.courseInfo.id),
      memberCode: String(user.member.id),
    });
  };

  return (
    <main className="content pb12">
      <div className="section pb6">
        <div className="flex-wrap">
          <span className="flex-wrap__title">파트너 선생님</span>
          <div className="flex-wrap__cont">
            <p className="flex-wrap__text">
              {requestedCourseInfo?.courseInfo.tutorName}{" "}
              {maskMobileNumber(requestedCourseInfo?.courseInfo?.tutorMobile)}
            </p>
          </div>
        </div>

        <div className="flex-wrap">
          <span className="flex-wrap__title">학부모 연락처</span>
          <div className="parents-list">
            {requestedCourseInfo?.courseParent?.map(
              (parent: any, index: number) => (
                <div
                  className="parents-list__item"
                  key={`parents-list__item-${index}`}
                >
                  <div className="parents-list__body">
                    <i className="icon i-parents" />
                    <h5 className="parents-list__title">
                      <b>
                        {parent.studentName}({parent.studentInfo})
                      </b>
                      학부모
                    </h5>
                    <p className="parents-list__phone">{parent.parentMobile}</p>
                  </div>
                </div>
              ),
            )}
          </div>
        </div>

        <div className="flex-wrap pb6">
          <span className="flex-wrap__title">지역</span>
          <div className="flex-wrap__cont">
            <p className="flex-wrap__text">
              {requestedCourseInfo?.courseInfo.location}
            </p>
          </div>
        </div>

        <div className="divider" />

        <div className="flex-wrap">
          <span className="flex-wrap__title">과목</span>
          <div className="flex-wrap__cont">
            <div className="check-chip">
              {requestedCourseInfo?.courseInfo.className
                .split(",")
                .map((className: string, index: number) => (
                  <div
                    className="check-chip__item"
                    key={`class-names-item-${index}`}
                  >
                    <label htmlFor="chips1" className="check-chip__label">
                      <input type="checkbox" id="chips1" checked disabled />
                      <div className="check-chip__title selected">
                        <span>{className}</span>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="flex-wrap pb6">
          <div className="class-summary">
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>{requestedCourseInfo?.courseInfo.times}</b> 회
              </div>
              <div className="class-summary__title">수업 횟수</div>
            </div>
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>
                  {minutesToTimeString(
                    requestedCourseInfo?.courseInfo.hour ?? 0,
                  )}
                </b>
              </div>
              <div className="class-summary__title">회당 수업 시간</div>
            </div>
            <div className="class-summary__item">
              <div className="class-summary__info">
                <b>
                  {minutesToTimeString(
                    requestedCourseInfo?.courseInfo.totalHour ?? 0,
                  )}
                </b>
              </div>
              <div className="class-summary__title">총 수업 시간</div>
            </div>
          </div>
        </div>

        <div className="divider" />

        <div className="flex-wrap">
          <span className="flex-wrap__title">요일 및 시간</span>
          <div className="flex-wrap__cont">
            <ul className="tutoring-schedule tutoring-schedule--large">
              {dayInfo.map((courseInfo: any, index: number) => (
                <li
                  className="tutoring-schedule__item"
                  key={`tutoring-schedule-item-${index}`}
                >
                  <b>{numberToDay(courseInfo?.day)}</b>
                  {convertTimeFormat(courseInfo?.start)} ~
                  {convertTimeFormat(courseInfo?.end)}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-wrap flex-wrap--direction-row">
          <div className="flex-wrap flex-wrap--grow-1">
            <span className="flex-wrap__title">시작일</span>
            <div className="flex-wrap__cont">
              <p className="flex-wrap__text">
                <i className="icon i-date" />
                {moment(requestedCourseInfo?.courseInfo.startDate).format(
                  "YYYY.MM.DD(ddd)",
                )}
              </p>
            </div>
          </div>

          <div className="flex-wrap flex-wrap--grow-1">
            <span className="flex-wrap__title">예상 종료일</span>
            <div className="flex-wrap__cont">
              <p className="flex-wrap__text">
                <i className="icon i-date" />
                {moment(requestedCourseInfo?.courseInfo.endDate).format(
                  "YYYY.MM.DD(ddd)",
                )}
              </p>
            </div>
          </div>
        </div>

        {requestedCourseInfo?.courseInfo.shareStatus === "REQUEST" && (
          <div className="bottom-button-wrap py10">
            <button
              type="button"
              className="btn btn--full btn--primary"
              onClick={() => {
                setModalName("requestCancel");
              }}
            >
              요청취소
            </button>
          </div>
        )}

        {requestedCourseInfo?.courseInfo.shareStatus !== "REQUEST" && (
          <div className="flex-box flex-box--items-center flex-box--justify-end py10">
            <div className="status-info status-info--large">
              <span className={statusInfo?.className}>
                {statusInfo?.text} (
                {moment(requestedCourseInfo?.courseInfo.responseDate).format(
                  "YYYY.MM.DD",
                )}
                )
              </span>
            </div>
          </div>
        )}
      </div>
      <CommonModal
        open={modalName === "requestCancel"}
        modalClose={modalClose}
        title={"요청 취소"}
        text={"과외 요청을 취소하시겠습니까?"}
        onConfirm={handleConfirm}
      />
    </main>
  );
}

export default RequestedCourseInfoContainer;
