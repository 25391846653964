import { useRecoilValue, useResetRecoilState } from "recoil";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PartnerListContainer from "../PartnerListContainer";
import PartnerTutorFilter from "../filter/PartnerTutorFilter";
import { partnerTutorFilterAtom } from "../../../../store/partnerTutor/partnerTutorFilterStore";
import usePartnerTutorListInfiniteQuery from "../../../../hooks/partnerTutor/usePartnerTutorListQuery";
import ButtonDropDown from "../../../common/select/ButtonDropDown";
import GuideModal from "../../../common/modal/GuideModal";

function PartnerTutorAcceptTab() {
  const partnerTutorFilter = useRecoilValue(partnerTutorFilterAtom);
  const resetPartnerTutorFilter = useResetRecoilState(partnerTutorFilterAtom);
  // usePartnerTutorListInfiniteQuery에서 partnerTutorFilter를 의존성으로 사용
  const { data, fetchNextPage, hasNextPage } =
    usePartnerTutorListInfiniteQuery(partnerTutorFilter);
  const [modalName, setModalName] = useState("");
  const navigate = useNavigate();

  const observerElem = useRef(null);
  const [partnerTimeFilter, setPartnerTimeFilter] = useState("최신순");

  const onClickFilter = (filter: string) => {
    setPartnerTimeFilter(filter);
  };

  useEffect(() => {
    return () => {
      // unmount
      resetPartnerTutorFilter();
    };
  }, [resetPartnerTutorFilter]);

  // pages 내 각 items를 정렬하지만, InfiniteData 구조는 유지
  const sortedData = data
    ? {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          items: page.items.sort((a, b) => {
            const dateA = new Date(a.createdAt).getTime();
            const dateB = new Date(b.createdAt).getTime();
            return partnerTimeFilter === "과거순"
              ? dateA - dateB
              : dateB - dateA;
          }),
        })),
      }
    : undefined;

  useEffect(() => {
    const currentObserverElem = observerElem.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (currentObserverElem) {
      observer.observe(currentObserverElem);
    }

    return () => {
      if (currentObserverElem) {
        observer.unobserve(currentObserverElem);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  const totalItems =
    data?.pages?.reduce((acc, page) => acc + page.items.length, 0) || 0;

  return (
    <>
      <PartnerTutorFilter />
      {data && data?.pages[0].items.length > 0 ? (
        <>
          <div className="divider" />
          <div className="section pt5">
            <div className="sorting">
              <span className="sorting__title">
                <b>{totalItems}</b> 건
              </span>
              <ButtonDropDown
                buttonText={partnerTimeFilter}
                buttonIcon="i-arrow-down"
                buttonType={"filter"}
                items={[
                  { text: "과거순", onClick: () => onClickFilter("과거순") },
                  { text: "최신순", onClick: () => onClickFilter("최신순") },
                ]}
              />
            </div>

            {/* 정렬된 data 전달 */}
            <PartnerListContainer partnerTutorList={sortedData} />

            {/* 무한 스크롤을 위한 관찰 요소 */}
            <div ref={observerElem} />
          </div>
        </>
      ) : (
        <div className="page-empty">목록이 없습니다.</div>
      )}

      <div
        className="floating-btn2"
        role={"presentation"}
        onClick={() => {
          setModalName("guide");
        }}
      >
        <button className="floating-btn2__link">
          <i className="icon i-help" />
        </button>
      </div>

      <GuideModal
        open={modalName === "guide"}
        modalClose={() => {
          setModalName("");
        }}
        onClick={() => {
          navigate("/my-info/freq-question");
        }}
        titles={[
          {
            title: "파트너 선생님 가이드",
            nav: () => {
              navigate("/my-info/freq-question", {
                state: { category: "3" },
              });
            },
          },
          {
            title: "파트너 선생님 프로필 관리 가이드",
            nav: () => {
              navigate("/my-info/freq-question/info", {
                state: "33",
              });
            },
          },
        ]}
        buttonText={"자주 묻는 질문 바로가기"}
      />
    </>
  );
}

export default PartnerTutorAcceptTab;
