import { useQuery, UseQueryResult } from "react-query";
import { ProfileListType } from "../../types/profile/profileListTypes";
import ProfileApi from "../../services/profile/ProfileApi";

const useProfileListQuery = (
  id: string,
): UseQueryResult<ProfileListType, Error> => {
  return useQuery<ProfileListType, Error>(
    ["profileList", id],
    () => ProfileApi.fetchProfileList(id),
    {
      onSuccess: (data: ProfileListType) => {
        console.log("Query successful:", data);
      },
      onError: (error: Error) => {
        console.error("Query error:", error);
      },
    },
  );
};

export default useProfileListQuery;
