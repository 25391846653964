import { useQuery, UseQueryResult } from "react-query";
import { useSetRecoilState } from "recoil";
import { AlarmListType } from "../../../types/home/homeTypes";
import AlarmApi from "../../../services/home/alarm/AlarmApi";
import { alarmListAtom } from "../../../store/alarm/alarmListStore";

const useAlarmListQuery = (params: {
  memberCode: string;
}): UseQueryResult<AlarmListType> => {
  const setAlarmList = useSetRecoilState(alarmListAtom);

  return useQuery<AlarmListType>({
    queryKey: ["alarmList"],
    queryFn: () => AlarmApi.fetchAlarmList({ ...params }),
    onSuccess: (data: AlarmListType) => {
      console.log("Query successful:", data);
      setAlarmList(data.items);
    },
    onError: (error) => {
      console.error("Query error:", error);
    },
  });
};

export default useAlarmListQuery;
