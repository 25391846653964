import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import routes from "../../routes/routes";
import logo from "../../assets/img/symbol_hori.png";
import { AlarmType } from "../../types/home/homeTypes";
import { alarmListAtom } from "../../store/alarm/alarmListStore";
import { formDirtyStateAtom } from "../../store/common/formDirtyStateStore";
import CommonModal from "./modal/CommonModal";

interface Route {
  path: string;
  element: React.ReactNode;
  title?: string;
  children?: Route[];
  homeIcon?: boolean;
}

function findRouteByPath(routes: Route[], path: string): Route | null {
  // eslint-disable-next-line no-restricted-syntax
  for (const route of routes) {
    // 동적 경로 파라미터를 처리하는 정규식: /:id 같은 패턴을 슬래시를 제외한 모든 문자로 처리
    const pathToRegex = new RegExp(`^${route.path.replace(/:\w+/g, "[^/]+")}$`);

    // 경로가 일치하면 해당 route 반환
    if (pathToRegex.test(path)) {
      return route;
    }

    // 하위 경로(children)가 있는 경우 재귀적으로 검색
    if (route.children) {
      const foundChild = findRouteByPath(route.children, path);
      if (foundChild) {
        return foundChild;
      }
    }
  }
  return null;
}

const HeaderBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = findRouteByPath(routes, location.pathname);
  const alarmList = useRecoilValue(alarmListAtom);
  const [isExternalLink, setIsExternalLink] = useState(false);
  const pageTitle = currentRoute ? currentRoute.title : "Default Title";
  const unreadExists = alarmList?.some((item: AlarmType) => !item.readFlag);
  const isFormDirty = useRecoilValue(formDirtyStateAtom);
  const [modalName, setModalName] = useState("");

  useEffect(() => {
    const externalLinkRegex = /^\/pro\/pre\/ext\/[A-Za-z0-9%]+$/;
    if (externalLinkRegex.test(location.pathname)) {
      setIsExternalLink(true);
    }
  }, [location.pathname]);

  const handleBackClick = () => {
    if (isFormDirty) {
      setModalName("formDirty");
    } else {
      navigate(-1);
    }
  };

  const handleConfirm = () => {
    navigate(-1);
    setModalName("");
  };

  return (
    <>
      {location.pathname === "/home" ? (
        <header id="app-header">
          <div className="global-nav-bar">
            <div className="global-nav-bar__button">
              <img src={logo} alt={"logo"} style={{ height: "26px" }} />
            </div>
            <div className="global-nav-bar__title" />
            <div
              className="global-nav-bar__control"
              role={"presentation"}
              onClick={() => {
                navigate("/alarm-list");
              }}
            >
              <p className="global-nav-bar__link">
                <i className={`icon i-alarm ${unreadExists && "is-new"}`} />
              </p>
            </div>
          </div>
        </header>
      ) : (
        <header id="app-header">
          <div className="global-nav-bar">
            {location.pathname !== "/" &&
            location.pathname !== "/tutorial" &&
            !isExternalLink ? (
              <div
                role={"presentation"}
                className="global-nav-bar__button"
                onClick={handleBackClick}
              >
                <i className="icon i-arrow-back" />
              </div>
            ) : (
              <>&nbsp;</>
            )}

            <div className="global-nav-bar__title">{pageTitle}</div>

            {currentRoute?.homeIcon && (
              <div
                className="global-nav-bar__control"
                role={"presentation"}
                onClick={() => {
                  navigate("/home");
                }}
              >
                <i className="icon i-home" />
              </div>
            )}

            {["/course-management/proceeding", "/study-calendal"].includes(
              location.pathname,
            ) && (
              <div className="global-nav-bar__control">
                <i className="icon i-alarm is-new" />
              </div>
            )}

            {location.pathname === "/partner-tutor/study-calendar" && (
              <div
                className="global-nav-bar__control"
                role={"presentation"}
                onClick={() => {
                  navigate("/partner-tutor/course-management/proceeding");
                }}
              >
                <div className="global-nav-bar__link">과외관리</div>
              </div>
            )}
          </div>
        </header>
      )}
      <CommonModal
        open={modalName === "formDirty"}
        modalClose={() => {
          setModalName("");
        }}
        text={"이 페이지에서 나가시겠습니까?"}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default HeaderBar;
