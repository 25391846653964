import Modal from "@mui/material/Modal";
import { useEffect } from "react";
import { setBackPressOn } from "../../../gnote_api";
export default function GuideModal({
  open,
  modalClose,
  onClick,
  titles,
  buttonText,
}: {
  open: boolean;
  modalClose: () => void;
  onClick: () => void;
  titles: { title: string; nav: () => void }[];
  buttonText: string;
}) {
  useEffect(() => {
    if (open) {
      setBackPressOn(false);
    } else {
      setBackPressOn(true);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={modalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <div className="modal">
          <div
            className="modal__container"
            style={{ padding: "1rem 1rem 1rem" }}
          >
            <div className="flex-box flex-box--justify-end">
              <button
                type="button"
                className="modal__close"
                onClick={modalClose}
              >
                <i className="icon i-close" />
              </button>
            </div>
            <div className="modal__header">도움말</div>
            <div className="modal__body">
              {titles.map((item) => (
                <>
                  <div
                    role={"presentation"}
                    onClick={item.nav}
                    className="flex-box flex-box--justify-between flex-box--items-center py3"
                  >
                    <span className="typo-medium color-black">
                      {item.title}
                    </span>
                    <i className="icon i-arrow-right" />
                  </div>
                  <div className="divider divider--thin mx0" />
                </>
              ))}
              <button
                type="button"
                className="btn btn--full btn--small btn--brown mt3"
                onClick={onClick}
              >
                {buttonText}
              </button>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
