import { useNavigate } from "react-router-dom";
import "moment/locale/ko";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";
import { useEffect, useRef } from "react";
import { RequestedCourseType } from "../../../types/requestedCourse/requestedCourseTypes";
import {
  convertTimeFormat,
  numberToDay,
  translateValue,
} from "../../../util/util";
import {
  CourseClassStatusType,
  CourseInfoType,
  CourseParentType,
  CourseType,
} from "../../../types/courseManagement/courseManagementTypes";
import SelectDropDown from "../select/SelectDropDown";
import { courseIndexAtom } from "../../../store/courseManagement/courseIndexStore";
import { configAtom } from "../../../store/common/configStore";

function CourseCard({
  data,
  cardtype,
  courseParent,
  courseClassStatus,
}: {
  data: RequestedCourseType | CourseType | CourseInfoType | undefined;
  cardtype: string;
  courseParent?: CourseParentType;
  courseClassStatus?: CourseClassStatusType;
}) {
  const navigate = useNavigate();
  const [courseIndex, setCourseIndex] = useRecoilState(courseIndexAtom);
  const config = useRecoilValue(configAtom);
  const hasInitialized = useRef(false);

  function getStatusInfo(status: string | undefined) {
    switch (status) {
      case "ACCEPT":
        return { text: "승인 완료", className: "complete" };
      case "REQUEST":
        return { text: "승인 대기", className: "waiting" };
      case "REJECT":
        return { text: "승인 거절", className: "refuse" };
      case "EXPIRE":
        return { text: "기간 만료", className: "refuse" };
      case "CANCEL":
        return { text: "요청 취소", className: "refuse" };
      default:
        return null;
    }
  }

  const statusInfo = getStatusInfo(data?.shareStatus);

  const onCardClick = () => {
    if (cardtype !== "requested" && cardtype !== "info") {
      navigate("/partner-tutor/course-management/course-info", {
        state: data?.id,
      });
    }
  };

  // 컴포넌트 렌더링 시 차수 셀렉트를 현재 진행중인 차수를 default값으로 설정
  useEffect(() => {
    // 초기화가 아직 안된 상태에서만 실행
    if (!hasInitialized.current && courseClassStatus) {
      const ongoingKey = Object.keys(courseClassStatus).find(
        (key) => courseClassStatus[key] === "ONGOING",
      );

      if (ongoingKey) {
        setCourseIndex(ongoingKey);
      } else {
        // `ONGOING` 상태가 없을 때 첫 번째 key를 기본값으로 설정
        const firstKey = Object.keys(courseClassStatus)[0];
        setCourseIndex(firstKey);
      }

      hasInitialized.current = true;
    }
  }, [courseClassStatus, setCourseIndex]);

  const convertCourseIndex = (
    data: { [key: number]: string } | undefined,
  ): { [key: number]: string } => {
    if (!data) return {};

    return Object.keys(data).reduce(
      (acc, key) => {
        const courseIndex = Number(key);
        const status = data[courseIndex];
        acc[courseIndex] =
          status === "ONGOING"
            ? `${translateValue(String(courseIndex), config.ordinalNum)}(진행중)`
            : `${translateValue(String(courseIndex), config.ordinalNum)}`;

        return acc;
      },
      {} as { [key: number]: string },
    );
  };

  const courseIndexList = convertCourseIndex(courseClassStatus);

  const handleChange = (newValue: string) => {
    setCourseIndex(newValue);
  };

  return (
    <>
      <div
        className="tutoring-list__item"
        role={"presentation"}
        onClick={onCardClick}
      >
        <div className="tutoring-list__link">
          <div className="tutoring-list__header">
            {data?.tutorName && (
              <div className="chips">
                <span className="chips__item">{data?.tutorName} 선생님</span>
              </div>
            )}
            {cardtype === "requested" && (
              <div
                className="text-btn"
                role={"presentation"}
                onClick={() => {
                  navigate("/requested-course/info", {
                    state: data?.id,
                  });
                }}
              >
                상세보기 <i className="icon i-arrow-right" />
              </div>
            )}
          </div>
          <div className="tutoring-list__body">
            <h5 className="tutoring-list__title">
              {cardtype === "info" ? (
                <>
                  {courseParent
                    ?.map((item: { studentName: string }) => item.studentName)
                    .join(",")}
                </>
              ) : (
                <>
                  {data?.studentNames
                    ?.map(
                      (student: { studentName: string }) => student.studentName,
                    )
                    .join(", ")}
                </>
              )}
              <em className="dot" />
              {data?.className}
              <em className="dot" />
              {data?.location}
            </h5>

            {cardtype === "info" && (
              <ul className="tutoring-schedule">
                {courseClassStatus?.[courseIndex] === "ONGOING" &&
                  data?.dayInfoJson &&
                  JSON.parse(data?.dayInfoJson)?.data?.map(
                    (
                      data: {
                        day: number;
                        start: string;
                        end: string;
                      },
                      index: number,
                    ) => (
                      <li
                        key={`tutoring-schedule__item-${index}`}
                        className="tutoring-schedule__item"
                      >
                        <b>{numberToDay(data.day)}</b>
                        {convertTimeFormat(data.start)} ~{" "}
                        {convertTimeFormat(data.end)}
                      </li>
                    ),
                  )}
                {courseClassStatus?.[courseIndex] !== "ONGOING" && (
                  <li className="tutoring-schedule__item">
                    <b>기간</b> {moment(data?.startDate).format("YYYY.MM.DD")}~
                    {moment(data?.endDate).format("YYYY.MM.DD")}
                  </li>
                )}
              </ul>
            )}
            {/* 과외 메인 화면에서 수업 일정 */}
            {cardtype !== "info" && (
              <ul className="tutoring-schedule">
                {(data?.status === "ONGOING" || data?.status === "CANCEL") &&
                  data?.dayInfoJson &&
                  JSON.parse(data?.dayInfoJson)?.data?.map(
                    (
                      data: {
                        day: number;
                        start: string;
                        end: string;
                      },
                      index: number,
                    ) => (
                      <li
                        key={`tutoring-schedule__item-${index}`}
                        className="tutoring-schedule__item"
                      >
                        <b>{numberToDay(data.day)}</b>
                        {convertTimeFormat(data.start)} ~{" "}
                        {convertTimeFormat(data.end)}
                      </li>
                    ),
                  )}
                {data?.status === "CLOSE" && (
                  <li className="tutoring-schedule__item">
                    <b>기간</b> {moment(data?.startDate).format("YYYY.MM.DD")}~
                    {moment(data?.endDate).format("YYYY.MM.DD")}
                  </li>
                )}
              </ul>
            )}
          </div>

          {/* 과외 요청 리스트에서 쓰일 때 */}
          {cardtype === "requested" && (
            <>
              <div className="tutoring-list__footer">
                <p className="tutoring-list__request-date">
                  요청일: {moment(data?.requestDate).format("YYYY.MM.DD")}
                </p>
                <div className="status-info">
                  <span className={statusInfo?.className}>
                    {statusInfo?.text}
                  </span>
                </div>
              </div>
            </>
          )}

          {cardtype === "info" && (
            <div className="tutoring-list__footer2 tutoring-list__footer2--items-end">
              <SelectDropDown
                initialLabel="선택"
                options={courseIndexList}
                fontSize={"0.9rem"}
                isFull
                value={courseIndex}
                onChange={handleChange}
                reverse
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CourseCard;
