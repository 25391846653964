import React, { useState, useRef, useEffect } from "react";

interface SelectDropDownProps {
  initialLabel: string;
  options: { [key: string]: string };
  isFull: boolean;
  disabled?: boolean;
  value: string;
  onChange: (newValue: string) => void;
  type?: string;
  fontSize?: string;
  reverse?: boolean;
}

const SelectDropDown: React.FC<SelectDropDownProps> = ({
  initialLabel,
  options,
  isFull,
  disabled,
  value,
  onChange,
  type,
  fontSize,
  reverse,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(
    value && (type === "classNumber" || options[value]) ? value : initialLabel,
  );
  const selectBoxRef = useRef<HTMLDivElement>(null);

  const toggleSelectBox = () => {
    if (!disabled) {
      setExpanded(!expanded);
    }
  };

  const selectOption = (selectedKeyOrValue: string) => {
    if (type === "classNumber") {
      setSelectedLabel(selectedKeyOrValue);
    } else {
      setSelectedLabel(options[selectedKeyOrValue]);
    }
    setExpanded(false);
    onChange(selectedKeyOrValue);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      selectBoxRef.current &&
      !selectBoxRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (type === "classNumber") {
      setSelectedLabel(value || initialLabel);
    } else if (type !== "classNumber" && options[value]) {
      setSelectedLabel(options[value]);
    } else {
      setSelectedLabel(initialLabel);
    }
  }, [value, initialLabel, options, type]);

  const getOptionsToDisplay = (): string[] => {
    if (type === "classNumber") {
      return Object.values(options);
    }
    if (reverse) {
      return Object.keys(options).reverse();
    }
    return Object.keys(options);
  };

  return (
    <div className={`select-dropdown ${isFull ? "select-dropdown--full" : ""}`}>
      <div
        role="presentation"
        className={`select-dropdown__inner ${
          expanded ? "expanded" : ""
        } ${disabled ? "select-dropdown__inner--disabled" : ""}`}
        onClick={toggleSelectBox}
        ref={selectBoxRef}
      >
        <div className="select-dropdown__arrow" />
        <div className="select-dropdown__label">
          <span style={{ fontSize }}>{selectedLabel}</span>
        </div>

        {expanded && (
          <ul className="select-dropdown__list">
            {getOptionsToDisplay().map((optionItem: string) => (
              <li
                role="presentation"
                key={`select-dropdown-list-ul-li-${optionItem}`}
                className="select-dropdown__item"
                onClick={() => selectOption(optionItem)}
              >
                {type === "classNumber" ? optionItem : options[optionItem]}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default SelectDropDown;
