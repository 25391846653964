import { Box, Modal } from "@mui/material";
import { extractYoutubeId } from "../../../util/util";

export function EnlargeBox({
  type,
  open,
  close,
  value,
}: {
  type: "img" | "video" | "youtube";
  open: boolean;
  close: () => void;
  value: any;
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  };

  return (
    <>
      {type === "img" && value && (
        <Modal open={open} onClose={close}>
          <Box sx={style}>
            <img width="300" height="400" src={value} alt="contentImage" />
          </Box>
        </Modal>
      )}

      {type === "video" && value && (
        <Modal open={open} onClose={close}>
          <Box sx={style}>
            <video width="300" height="300" src={value} controls>
              <track kind="captions" />
            </video>
          </Box>
        </Modal>
      )}

      {type === "youtube" && value && (
        <Modal open={open} onClose={close}>
          <Box sx={style}>
            <iframe
              width="300"
              height="300"
              src={`https://www.youtube.com/embed/${extractYoutubeId(value)}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        </Modal>
      )}
    </>
  );
}
