import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useRecoilState } from "recoil";
import { enqueueSnackbar } from "notistack";
import RadioBoxGroup from "../../../common/select/RadioBoxGroup";
import CheckBoxGroup from "../../../common/select/CheckBoxGroup";
import { partnerTutorFilterAtom } from "../../../../store/partnerTutor/partnerTutorFilterStore";
import { setBackPressOn } from "../../../../gnote_api";

function FilterBottomSheetModal({
  setBottomSheetName,
  radioOption = {},
  checkBoxOption = {},
  title,
  subTitle,
}: {
  setBottomSheetName: React.Dispatch<React.SetStateAction<string>>;
  radioOption?: { [key: string]: string };
  checkBoxOption?: { [key: string]: string };
  title: string;
  subTitle: string[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [partnerTutorFilter, setPartnerTutorFilter] = useRecoilState(
    partnerTutorFilterAtom,
  );
  const [selectedGender, setSelectedGender] = useState<string>(
    partnerTutorFilter?.gender || "",
  );
  const [selectedAgeGroups, setSelectedAgeGroups] = useState<string[]>(
    partnerTutorFilter?.ageGroup?.split(",") || [],
  );
  // class타입을 "FACE", "ONLINE" 둘다 선택하면 ALL
  const [selectedClassType, setSelectedClassType] = useState<string[]>(() => {
    const classType = partnerTutorFilter?.classType;
    return classType === "ALL"
      ? ["FACE", "ONLINE"]
      : classType?.split(",") || [];
  });

  const [selectedDemoClassType, setSelectedDemoClassType] = useState<string>(
    partnerTutorFilter?.demoClassType || "",
  );

  const handleAnimationComplete = () => {
    if (!isOpen) {
      setBottomSheetName("");
    }
  };

  const handleSave = () => {
    if (title === "인적 조건") {
      setPartnerTutorFilter((prevReq: any) => ({
        ...prevReq,
        name: "",
        gender: selectedGender,
        ageGroup: selectedAgeGroups.join(","),
      }));
    } else if (title === "과외 조건") {
      setPartnerTutorFilter((prevReq: any) => ({
        ...prevReq,
        name: "",
        classType:
          selectedClassType.includes("FACE") &&
          selectedClassType.includes("ONLINE")
            ? "ALL"
            : selectedClassType.join(",") || null,
        demoClassType: selectedDemoClassType || null,
      }));
    }
    setIsOpen(false);
  };

  const handleReset = () => {
    if (title === "인적 조건") {
      setPartnerTutorFilter((prevReq: any) => ({
        ...prevReq,
        gender: null,
        ageGroup: null,
      }));
      setSelectedGender("");
      setSelectedAgeGroups([]);
    } else if (title === "과외 조건") {
      setPartnerTutorFilter((prevReq: any) => ({
        ...prevReq,
        classType: null,
        demoClassType: null,
      }));
      setSelectedClassType([]);
      setSelectedDemoClassType("");
    }
    enqueueSnackbar("초기화되었습니다.");
  };

  // 스크롤 잠금 처리
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setBackPressOn(false);
    } else {
      setBackPressOn(true);
    }
  }, [isOpen]);

  return (
    <div className="bottom-sheet">
      <motion.div
        className="bottom-sheet__container"
        initial={{ y: "100%" }}
        animate={{ y: isOpen ? "0%" : "100%" }}
        transition={{ type: "spring", stiffness: 250, damping: 30 }}
        onAnimationComplete={handleAnimationComplete}
      >
        <button
          type="button"
          className="bottom-sheet__close"
          onClick={() => setIsOpen(false)}
        >
          <i className="icon i-close" />
        </button>

        <div className="bottom-sheet__header">{title}</div>

        <div className="bottom-sheet__body">
          {title === "인적 조건" && (
            <>
              <RadioBoxGroup
                addClass={"mt0"}
                radioOption={radioOption}
                subTitle={subTitle[0]}
                selectedValue={selectedGender}
                setSelectedValue={setSelectedGender}
              />
              <CheckBoxGroup
                checkBoxOption={checkBoxOption}
                subTitle={subTitle[1]}
                selectedGroups={selectedAgeGroups}
                setSelectedGroups={setSelectedAgeGroups}
              />
            </>
          )}

          {title === "과외 조건" && (
            <>
              <CheckBoxGroup
                addClass={"mt0"}
                checkBoxOption={checkBoxOption}
                subTitle={subTitle[0]}
                selectedGroups={selectedClassType}
                setSelectedGroups={setSelectedClassType}
              />
              <RadioBoxGroup
                radioOption={radioOption}
                subTitle={subTitle[1]}
                selectedValue={selectedDemoClassType}
                setSelectedValue={setSelectedDemoClassType}
              />
            </>
          )}
        </div>

        <div className="bottom-sheet__footer">
          <button
            type="button"
            className="btn btn--full btn--gray"
            onClick={handleReset}
          >
            선택항목 초기화
          </button>
          <button
            type="button"
            className="btn btn--full btn--primary"
            onClick={handleSave}
          >
            저장하기
          </button>
        </div>
      </motion.div>
    </div>
  );
}

export default FilterBottomSheetModal;
