import React, { useState, useEffect, SetStateAction, useRef } from "react";
import { useRecoilValue } from "recoil";
import usePartnerTutorSearchListQuery from "../../../hooks/partnerTutor/usePartnerTutorSearchListQuery";
import { PartnerTutorSearchType } from "../../../types/partnerTutor/partnerTutorTypes";
import { maskMobileNumber } from "../../../util/util";
import { userAtom } from "../../../store/common/userStore";

const CommonSearchForm = ({
  closeForm,
  setValue,
  searchType,
}: {
  closeForm: () => void;
  setValue: React.Dispatch<
    SetStateAction<{ id: number; name: string; mobile: string }>
  >;
  searchType: string;
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<PartnerTutorSearchType[]>(
    [],
  );
  const [isSearched, setIsSearched] = useState<boolean>(false); // 검색 시도 여부를 추적하는 상태
  const user = useRecoilValue(userAtom);

  const handleInputChange = (e: { target: { value: string } }) => {
    setInputValue(e.target.value);
  };

  const handleListClick = (
    item: React.SetStateAction<{ id: number; name: string; mobile: string }>,
  ) => {
    setValue(item);
    closeForm();
  };

  const { data } = usePartnerTutorSearchListQuery({
    searchName: "",
    businessCode: String(user.member.id),
    searchType,
  });

  useEffect(() => {
    if (inputValue !== "") {
      setIsSearched(true); // 입력값이 있을 때 검색 시도 상태를 true로 설정
      if (data && data.items) {
        let filteredItems: PartnerTutorSearchType[] = data.items;

        filteredItems = filteredItems.filter((item) =>
          item.name.includes(inputValue),
        );

        setFilteredData(filteredItems);
      }
    } else {
      setIsSearched(false); // 입력값이 없을 때는 검색 시도 상태를 false로 초기화
      setFilteredData([]);
    }
  }, [inputValue, data]);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="overlayContainer">
      <div className="overlay-search" style={{ display: "flex" }}>
        <div className="overlay-search__content">
          <div className="overlay-search__head">
            <button
              className="closeButton"
              onClick={() => {
                closeForm();
              }}
            >
              <i className="icon i-arrow-back" />
            </button>
            <input
              ref={inputRef}
              type="text"
              id="searchInput"
              value={inputValue}
              onChange={handleInputChange}
              className="overlay-search__input"
              placeholder="선생님 이름 검색"
            />
          </div>
          <div className="overlay-search__body">
            {filteredData.length > 0 ? (
              <ul className="overlay-results-list">
                {filteredData.map(
                  (item: PartnerTutorSearchType, index: number) => (
                    <li
                      role={"presentation"}
                      onClick={() => {
                        handleListClick(item);
                      }}
                      key={`data-${index}`}
                    >
                      {item.name} {maskMobileNumber(item.mobile)}
                    </li>
                  ),
                )}
              </ul>
            ) : (
              isSearched && (
                <div className="msg-ly my4 mx4">
                  <p className="msg-ly__text color-gray-700">
                    검색 결과가 없습니다.
                  </p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonSearchForm;
