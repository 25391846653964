import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useResetRecoilState } from "recoil";
import MemberApi from "../../services/member/MemberApi";
import { userAtom } from "../../store/common/userStore";

interface WithdrawParams {
  withDrawCode: string;
  memberType: string;
}

const useMemberWithDrawMutation = (modalClose: () => void) => {
  const navigate = useNavigate();
  const resetUser = useResetRecoilState(userAtom);
  return useMutation(
    (params: WithdrawParams) => {
      return MemberApi.fetchMemberWithdraw({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        modalClose();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenExp");
        resetUser();
        navigate("/");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useMemberWithDrawMutation;
