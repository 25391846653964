import React from "react";
import RequestedCourseDetailContainer from "../components/template/requestedCourse/RequestedCourseInfoContainer";
import AddCourseRequestContainer from "../components/template/requestedCourse/addCourseRequest/AddCourseRequestContainer";
import PartnerTutorAcceptTab from "../components/template/partnerTutor/patnerTabs/PartnerTutorAcceptTab";
import PartnerTutorInterestTab from "../components/template/partnerTutor/patnerTabs/PartnerTutorInterestTab";
import PartnerTutorRequestedTab from "../components/template/partnerTutor/patnerTabs/PartnerTutorRequestedTab";
import ProfileOriginalTabContainer from "../components/template/profile/tab/ProfileOriginalTabContainer";
import ProfileCopyTabContainer from "../components/template/profile/tab/ProfileCopyTabContainer";
import StudyCalendarContainer from "../components/template/studyCalendar/StudyCalendarContainer";
import CourseManagementContainer from "../components/template/courseManagement/CourseManagementContainer";
import ProceedingCourseTabContainer from "../components/template/courseManagement/ProceedingCourseTabContainer";
import CompletedCourseTabContainer from "../components/template/courseManagement/CompletedCourseTabContainer";
import CourseInfoContainer from "../components/template/courseManagement/CourseInfoContainer";
import SignUpForm from "../components/template/auth/signup/SignUpForm";
import SignUpComplete from "../components/template/auth/signup/SignUpComplete";
import FindEmailPasswordContainer from "../components/template/auth/FindEmailPasswordContainer";
import FindEmailTab from "../components/template/auth/FindEmailTab";
import FindPasswordTab from "../components/template/auth/findPassword/FindPasswordTab";
import MyInfoManagementSecurityCheck from "../components/template/myInfo/myInfoManagement/MyInfoManagementSecurityCheck";
import MyInfoManagementContainer from "../components/template/myInfo/myInfoManagement/MyInfoManagementContainer";
import AlarmSetting from "../components/template/myInfo/AlarmSetting";
import AlarmListContainer from "../components/template/home/alarm/AlarmListContainer";
// import ProfileInfoPreview from "../components/template/profile/info/ProfileInfoPreview";
import OnBoarding from "../components/common/OnBoarding";
import PrivacyPolicyContainer from "../components/template/myInfo/PrivacyPolicyContainer";
import TermsOfServiceContainer from "../components/template/myInfo/TermsOfServiceContainer";
import MobileAuthConfirm from "../components/template/auth/mobileAuth/MobileAuthConfirm";
import SignUpMobileAuth from "../components/template/auth/signup/SignUpMobileAuth";
import EncryptedProfileRedirect from "../components/template/externerLink/EncryptedProfileRedirect";
import NoticeInfo from "../components/template/myInfo/board/notice/NoticeInfo";
import NoticeContainer from "../components/template/myInfo/board/notice/NoticeContainer";
import QuestionInfo from "../components/template/myInfo/board/qna/QuestionInfo";
import QuestionAddForm from "../components/template/myInfo/board/qna/QuestionAddForm";
import QuestionContainer from "../components/template/myInfo/board/qna/QuestionContainer";
import FreqQuestionContainer from "../components/template/myInfo/board/faq/FreqQuestionContainer";
import FreqQuestionInfo from "../components/template/myInfo/board/faq/FreqQuestionInfo";
const RequestedCourseApp = React.lazy(
  async () => await import("../pages/RequestedCourse"),
);
const LoginApp = React.lazy(async () => await import("../pages/Login"));
const HomeApp = React.lazy(async () => await import("../pages/Home"));
const PartnerTutorApp = React.lazy(
  async () => await import("../pages/PartnerTutor"),
);
const ProfileApp = React.lazy(async () => await import("../pages/Profile"));
const MyInfoApp = React.lazy(async () => await import("../pages/MyInfo"));
const TutorialApp = React.lazy(async () => await import("../pages/Tutorial"));

const routes = [
  // 온보딩
  {
    path: "/",
    element: <OnBoarding />,
  },
  // 로그인
  { path: "/login", element: <LoginApp />, title: "로그인" },

  // 본인인증 검증
  {
    path: "/mobile-auth-confirm",
    element: <MobileAuthConfirm />,
  },

  { path: "/home", element: <HomeApp /> },

  // 회원가입
  {
    path: "/signup",
    element: <SignUpForm />,
    title: "사업자 회원가입",
  },
  // 회원가입 휴대폰 인증
  {
    path: "/signup/mobile-auth",
    element: <SignUpMobileAuth />,
    title: "사업자 회원가입",
  },
  // 회원가입 완료
  {
    path: "/signup-complete",
    element: <SignUpComplete />,
    title: "사업자 회원가입",
  },

  // 이메일,비밀번호 찾기
  {
    path: "/find-login-info",
    element: <FindEmailPasswordContainer />,
    children: [
      {
        path: "/find-login-info/email",
        element: <FindEmailTab />,
        title: "이메일/비밀번호 찾기",
      },
      {
        path: "/find-login-info/password",
        element: <FindPasswordTab />,
        title: "이메일/비밀번호 찾기",
      },
    ],
  },

  // 알림 리스트
  {
    path: "/alarm-list",
    element: <AlarmListContainer />,
    title: "알림",
  },

  // 내정보
  {
    path: "/my-info",
    element: <MyInfoApp />,
    title: "내 정보",
    homeIcon: true,
  },

  // 문의하기 리스트
  {
    path: "/my-info/question",
    element: <QuestionContainer />,
    title: "문의하기",
  },

  // 문의하기 추가
  {
    path: "/my-info/question/add",
    element: <QuestionAddForm />,
    title: "문의하기",
  },

  // 문의하기 상세
  {
    path: "/my-info/question/info",
    element: <QuestionInfo />,
    title: "문의하기",
  },

  // 문의하기 리스트
  {
    path: "/my-info/notice",
    element: <NoticeContainer />,
    title: "공지사항",
  },

  // 문의하기 상세
  {
    path: "/my-info/notice/info",
    element: <NoticeInfo />,
    title: "공지사항",
  },

  // 자주 묻는 질문
  {
    path: "/my-info/freq-question",
    element: <FreqQuestionContainer />,
    title: "자주 묻는 질문",
  },

  // 자주 묻는 질문 상세
  {
    path: "/my-info/freq-question/info",
    element: <FreqQuestionInfo />,
    title: "자주 묻는 질문",
  },

  {
    path: "/my-info/security-check",
    element: <MyInfoManagementSecurityCheck />,
    title: "내 정보 관리",
    homeIcon: true,
  },

  {
    path: "/my-info/management",
    element: <MyInfoManagementContainer />,
    title: "내 정보 관리",
    homeIcon: true,
  },
  // 알림 설정
  {
    path: "/my-info/alarm-setting",
    element: <AlarmSetting />,
    title: "알림 설정",
    homeIcon: true,
  },

  // 파트너 선생님
  {
    path: "/partner-tutor",
    element: <PartnerTutorApp />,
    title: "파트너 선생님",
    children: [
      {
        path: "/partner-tutor/accept",
        element: <PartnerTutorAcceptTab />,
        title: "파트너 선생님",
        homeIcon: true,
      },
      {
        path: "/partner-tutor/interest",
        element: <PartnerTutorInterestTab />,
        title: "파트너 선생님",
        homeIcon: true,
      },
      {
        path: "/partner-tutor/requested",
        element: <PartnerTutorRequestedTab />,
        title: "파트너 선생님",
        homeIcon: true,
      },
    ],
  },

  // 과외 관리
  {
    path: "/partner-tutor/course-management",
    element: <CourseManagementContainer />,
    title: "과외 관리",
    children: [
      {
        path: "/partner-tutor/course-management/proceeding",
        element: <ProceedingCourseTabContainer />,
        title: "과외 관리",
        homeIcon: true,
      },
      {
        path: "/partner-tutor/course-management/completed",
        element: <CompletedCourseTabContainer />,
        title: "과외 관리",
        homeIcon: true,
      },
    ],
  },

  // 진행중/완료 과외 상세
  {
    path: "/partner-tutor/course-management/course-info",
    element: <CourseInfoContainer />,
    title: "과외 상세",
  },

  // 수업 달력
  {
    path: "/partner-tutor/study-calendar",
    element: <StudyCalendarContainer />,
    title: "수업 달력",
  },

  // 과외 요청 리스트
  {
    path: "/requested-course",
    element: <RequestedCourseApp />,
    title: "과외 요청",
    homeIcon: true,
  },

  // 과외 요청 상세
  {
    path: "/requested-course/info",
    element: <RequestedCourseDetailContainer />,
    title: "과외 요청",
    homeIcon: true,
  },

  // 과외 요청 추가 폼
  {
    path: "/requested-course/add-course-request",
    element: <AddCourseRequestContainer />,
    title: "과외 요청",
    homeIcon: true,
  },

  // 프로필 관리
  {
    path: "/profile",
    element: <ProfileApp />,
    title: "프로필 관리",
    children: [
      {
        path: "/profile/original",
        element: <ProfileOriginalTabContainer />,
        title: "프로필 관리",
        homeIcon: true,
      },
      {
        path: "/profile/copy",
        element: <ProfileCopyTabContainer />,
        title: "프로필 관리",
        homeIcon: true,
      },
    ],
  },

  // // 프로필 미리보기
  // {
  //   path: "/profile/preview/:id",
  //   element: <ProfileInfoPreview />,
  //   title: "선생님 프로필",
  // },

  // 프로필 외부링크
  {
    path: "/pro/pre/ext/:encryptedLink",
    element: <EncryptedProfileRedirect />,
    title: "선생님 프로필",
  },

  // 튜토리얼
  {
    path: "/tutorial",
    element: <TutorialApp />,
  },

  // 개인정보 처리방침
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyContainer noModal />,
    title: "개인정보 처리방침",
  },

  // 이용약관
  {
    path: "/terms-service",
    element: <TermsOfServiceContainer noModal />,
    title: "이용약관",
  },
];

export default routes;
