import { useRecoilValue, useSetRecoilState } from "recoil";
import { useState } from "react";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { PartnerTutorType } from "../../../types/partnerTutor/partnerTutorTypes";
import usePartnerTutorInterestAddMutation from "../../../hooks/partnerTutor/interest/usePartnerTutorInterestAddMutation";
import usePartnerTutorInterestDeleteMutation from "../../../hooks/partnerTutor/interest/usePartnerTutorInterestDeleteMutation";
import { userAtom } from "../../../store/common/userStore";
import ButtonDropDown from "../../common/select/ButtonDropDown";
import CommonModal from "../../common/modal/CommonModal";
import usePartnerTutorDeleteMutation from "../../../hooks/partnerTutor/usePartnerTutorDeleteMutation";
import MemoEditBottomSheetModal from "./modal/MemoEditBottomSheetModal";
import usePartnerTutorProfileRequestMutation from "../../../hooks/partnerTutor/profile/usePartnerTutorProfileRequestMutation";
import { selectedPartnerInfoAtom } from "../../../store/profile/selectedPartnerInfoStore";
import ProfileTransferModal from "../profile/modal/ProfileTransferModal";
import defaultProfileImage from "../../../assets/img/profile_default.jpg";

function PartnerCard({
  data,
  type,
}: {
  data: PartnerTutorType;
  type?: string;
}) {
  const partnerTutorInterestAddMutation = usePartnerTutorInterestAddMutation();
  const partnerTutorInterestDeleteMutation =
    usePartnerTutorInterestDeleteMutation();
  const setSelectedPartnerInfo = useSetRecoilState(selectedPartnerInfoAtom);
  const user = useRecoilValue(userAtom);
  const [modalName, setModalName] = useState<string>("");
  const modalClose = () => {
    setModalName("");
  };
  const partnerTutorDeleteMutation = usePartnerTutorDeleteMutation(modalClose);
  const partnerTutorProfileRequestMutation =
    usePartnerTutorProfileRequestMutation(modalClose);
  const navigate = useNavigate();

  const onInterestClick = () => {
    if (data.interest) {
      partnerTutorInterestDeleteMutation.mutate({
        tutorCode: String(data.tutorCode),
        businessCode: String(user.member.id),
      });
    } else {
      partnerTutorInterestAddMutation.mutate({
        tutorCode: String(data.tutorCode),
        businessCode: String(user.member.id),
      });
    }
  };

  const handleDelete = () => {
    partnerTutorDeleteMutation.mutate({ id: String(data.mappingId) });
  };

  const handleProfileRequest = () => {
    partnerTutorProfileRequestMutation.mutate({
      tutorCode: String(data.tutorCode),
      businessCode: String(user.member.id),
    });
  };

  function getStatusInfo(status: string | undefined) {
    switch (status) {
      case "ACCEPT":
        return { text: "승인 완료", className: "complete" };
      case "REQUEST":
        return { text: "승인 대기", className: "waiting" };
      case "REJECT":
        return { text: "승인 거절", className: "refuse" };
      case "EXPIRED":
        return { text: "기간 만료", className: "refuse" };
      case "CANCELLED":
        return { text: "요청 취소", className: "refuse" };
      default:
        return null;
    }
  }

  const statusInfo = getStatusInfo(data?.status);

  const onClickProfile = () => {
    navigate("/profile/original");
    setSelectedPartnerInfo(data);
  };

  const onClickStudyCalendar = () => {
    navigate("/partner-tutor/study-calendar");
    setSelectedPartnerInfo(data);
  };

  const onClickProfileTransfer = () => {
    setModalName("profileTransfer");
    setSelectedPartnerInfo(data);
  };

  const getProfileImg = () => {
    if (data.profileImage === "" || data.profileImage === null) {
      return defaultProfileImage;
    }
    return data.profileImage.toString();
  };

  const openSMS = () => {
    console.log("click");
    window.location.href = `sms:${data.tutorMobile}`;
  };

  return (
    <>
      <div className="partner-bslist__item">
        {type !== "requested" && (
          <ButtonDropDown
            buttonIcon="i-more"
            items={[
              {
                text: "파트너해제",
                onClick: () => {
                  setModalName("delete");
                },
              },
            ]}
          />
        )}
        <div className="partner-bslist__body">
          <div className="partner-bslist__pic">
            <img src={getProfileImg()} alt={"profile"} />
          </div>
          <div className="partner-bslist__info">
            <h5 className="partner-bslist__title">
              <b>{data.tutorName} 선생님</b>
            </h5>
            <p className="partner-bslist__date">
              {data.memo ? data.memo : data.tutorMobile}
              {type !== "requested" && (
                <button
                  type="button"
                  className="btn-edit"
                  onClick={() => {
                    setModalName("memo");
                  }}
                >
                  <i className="icon i-edit" />
                </button>
              )}
            </p>
            <div className="partner-bslist__btn">
              <button>
                <a href={`tel:${data.tutorMobile}`}>
                  <i className="icon i-call" />
                </a>
              </button>
              <button onClick={openSMS}>
                <p>
                  <i className="icon i-mail" />
                </p>
              </button>
              {type !== "requested" && (
                <button
                  className={`btn-favorite ${data.interest && "active"}`}
                  onClick={onInterestClick}
                >
                  <i className="icon i-favorite" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="partner-bslist__footer">
          {type !== "requested" && (
            <>
              <button
                type="button"
                className="btn btn--small btn--full btn--bordered-primary"
                onClick={onClickStudyCalendar}
              >
                수업
              </button>
              {/* // 프로필이 모두 작성 되었는지 분기 처리 */}
              {data.profile ? (
                <>
                  {/* // 선생님이 프로필을 공개 했는지 분기 처리 */}
                  {data.publicFlag === "Y" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn--small btn--full btn--bordered-primary"
                        onClick={onClickProfile}
                      >
                        프로필 관리
                      </button>
                      <button
                        type="button"
                        className="btn btn--small btn--full btn--bordered-primary"
                        onClick={onClickProfileTransfer}
                      >
                        프로필 전송
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        className="btn btn--small btn--full btn--bordered-primary is-disabled"
                        onClick={onClickProfile}
                      >
                        프로필 비공개
                      </button>
                      <button
                        type="button"
                        className="btn btn--small btn--full btn--bordered-primary is-disabled"
                        onClick={onClickProfileTransfer}
                      >
                        프로필 전송
                      </button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="btn btn--small btn--full btn--primary"
                    onClick={() => {
                      setModalName("profileRequest");
                    }}
                  >
                    프로필 작성 요청
                  </button>
                  <button
                    type="button"
                    className="btn btn--small btn--full btn--bordered-primary px2 is-disabled"
                  >
                    프로필 전송
                  </button>
                </>
              )}
            </>
          )}

          {type === "requested" && (
            <>
              <p className="partner-bslist__request-date">
                파트너 신청일: {moment(data?.requestDate).format("YYYY.MM.DD")}
              </p>
              <div className="status-info">
                <span className={statusInfo?.className}>
                  {statusInfo?.text}
                </span>
              </div>
            </>
          )}
        </div>
      </div>

      <CommonModal
        open={modalName === "delete"}
        modalClose={() => {
          setModalName("");
        }}
        title={"파트너 해제"}
        text={
          "파트너 해제를 하면 사업자와 선생님의 파트너 목록에서 모두 삭제됩니다. 파트너를 해제하시겠습니까?"
        }
        onConfirm={handleDelete}
      />

      <CommonModal
        open={modalName === "profileRequest"}
        modalClose={() => {
          setModalName("");
        }}
        title={"프로필 작성 요청"}
        text={
          "프로필 작성 요청시 선생님에게 프로필 작성 요청 푸시가 전송됩니다. 프로필 작성을 요청하시겠습니까?"
        }
        onConfirm={handleProfileRequest}
      />

      {modalName === "memo" && (
        <MemoEditBottomSheetModal
          mappingId={data.mappingId}
          initialValue={data.memo ? data.memo : data.tutorMobile}
          setBottomSheetName={setModalName}
        />
      )}

      {modalName === "profileTransfer" && (
        <ProfileTransferModal setModalName={setModalName} />
      )}
    </>
  );
}

export default PartnerCard;
