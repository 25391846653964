import { useMutation, useQueryClient } from "react-query";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import {
  AlarmSettingAlimUpdateParams,
  AlarmSettingEventUpdateParams,
  AlarmSettingMannerModeUpdateParams,
} from "../../../types/member/memberTypes";
import AlarmSettingApi from "../../../services/myInfo/AlarmSettingApi";

const useAlarmSettingAlimUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      params,
      id,
      endPoint,
    }: {
      params:
        | AlarmSettingAlimUpdateParams
        | AlarmSettingEventUpdateParams
        | AlarmSettingMannerModeUpdateParams;
      id: string;
      endPoint: string;
    }) => {
      return AlarmSettingApi.fetchAlarmSettingAlimUpdate(
        { ...params },
        id,
        endPoint,
      );
    },
    {
      onSuccess: (data, variables) => {
        console.log("Mutation successful:", data);
        console.log(variables);
        queryClient.invalidateQueries("alarmSetting");

        // params가 AlarmSettingEventUpdateParams인지를 체크
        if (
          "eventFlag" in variables.params &&
          variables.params.eventFlag === "Y"
        ) {
          enqueueSnackbar(
            `마케팅 정보 수신 동의 ${moment().format("YYYY.MM.DD")}`,
          );
        }
        if (
          "eventFlag" in variables.params &&
          variables.params.eventFlag === "N"
        ) {
          enqueueSnackbar(
            `마케팅 정보 수신 해제 ${moment().format("YYYY.MM.DD")}`,
          );
        }
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useAlarmSettingAlimUpdateMutation;
