import { AxiosError, AxiosResponse } from "axios";
import { enqueueSnackbar } from "notistack";

interface ErrorResponse {
  msg: string;
}

export default function AxiosResponseErrorHandler(
  error: AxiosError,
): Promise<AxiosResponse> {
  const responseData = error.response?.data as ErrorResponse;

  // 401 에러 처리
  if (error.response?.status === 401) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("tokenExp");

    if (!window.location.pathname.includes("/")) {
      window.location.href = "/";
    }
  }

  // 에러 메시지 표시
  if (responseData?.msg) {
    enqueueSnackbar(responseData?.msg, { variant: "error" });
  }

  return Promise.reject(responseData);
}
