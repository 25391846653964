import { useMutation } from "react-query";
import AuthApi from "../../services/auth/AuthApi";
import { SendMobileCodeMutationParams } from "../../types/auth/authTypes";

const useSendMobileMemberAuthCodeMutation = (
  setModalName: React.Dispatch<React.SetStateAction<string>>,
  setTimeLeft: React.Dispatch<React.SetStateAction<number | null>>,
) => {
  return useMutation(
    (params: SendMobileCodeMutationParams) => {
      return AuthApi.fetchSendMobileMemberAuthCode({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        setModalName("mobileAuth");
        setTimeLeft(180); // 3분(180초) 타이머 시작
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useSendMobileMemberAuthCodeMutation;
