import { Controller, FormProvider, useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userAtom } from "../../../../store/common/userStore";
import { useUpdatePasswordNoTokenMutation } from "../../../../hooks/auth/useUpdatePasswordNoTokenMutation";
import useUpdatePasswordMutation from "../../../../hooks/auth/useUpdatePasswordMutation";
import CommonInput from "../../../common/form/CommonInput";

function PasswordReset({ certNum, email }: { certNum: string; email: string }) {
  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = methods;

  const [isComplete, setIsComplete] = useState(false);
  const updatePasswordMutation = useUpdatePasswordMutation();
  const updatePasswordNoTokenMutation =
    useUpdatePasswordNoTokenMutation(setIsComplete);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const user = useRecoilValue(userAtom);

  const onSubmit = (formData: any) => {
    // 로그인 하지 않고 비밀번호찾기/재설정 할 시
    if (!accessToken) {
      updatePasswordNoTokenMutation.mutate({
        email,
        certNum,
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
        memberType: user.member.memberType || "BUSINESS",
      });
    } else {
      updatePasswordMutation.mutate({
        newPassword: formData.password,
        confirmPassword: formData.confirmPassword,
        memberType: user.member.memberType || "BUSINESS",
      });
    }
  };

  return (
    <>
      {!isComplete ? (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "contents" }}
          >
            <main className="content pb0">
              <div className="section pb16">
                <p className="typo-large strong mt7">비밀번호 재설정</p>

                <div className="field-group">
                  <label htmlFor="" className="field-group__label">
                    비밀번호
                  </label>
                  <Controller
                    name="password"
                    control={methods.control}
                    rules={{
                      required: "비밀번호는 필수 항목입니다.",
                      pattern: {
                        value:
                          /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]{8,20}$/,
                        message:
                          "영문/숫자/특수문자 3가지 조합(8~20자)으로 입력해주세요.",
                      },
                    }}
                    render={({ field }) => (
                      <CommonInput
                        placeholder="비밀번호를 입력해주세요"
                        field={field}
                        type="password"
                        error={errors.password?.message}
                      />
                    )}
                  />
                </div>

                <div className="field-group">
                  <label htmlFor="" className="field-group__label">
                    비밀번호 확인
                  </label>

                  <Controller
                    name="confirmPassword"
                    control={methods.control}
                    rules={{
                      required: "비밀번호 확인은 필수 항목입니다.",
                      validate: (value) =>
                        value === getValues("password") ||
                        "비밀번호와 비밀번호 확인의 번호가 일치하지 않습니다.",
                    }}
                    render={({ field }) => (
                      <CommonInput
                        placeholder="비밀번호를 다시 입력해주세요"
                        field={field}
                        type="password"
                        error={errors.confirmPassword?.message}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="content-footer">
                <button
                  id="btn-submit"
                  className={`btn btn--full btn--primary ${
                    !isValid ? "is-disabled" : ""
                  }`}
                  type="submit"
                >
                  확인
                </button>
              </div>
            </main>
          </form>
        </FormProvider>
      ) : (
        <main className="content pb0">
          <div className="section flex-box flex-box--items-center flex-box--justify-center">
            <p className="typo-medium">비밀번호 변경이 완료되었습니다.</p>
          </div>
          <div className="content-footer">
            <button
              id="btn-submit"
              className="btn btn--full btn--primary"
              type="button"
              onClick={() => {
                navigate("/login");
              }}
            >
              로그인
            </button>
          </div>
        </main>
      )}
    </>
  );
}

export default PasswordReset;
