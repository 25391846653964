import { useEffect, useRef } from "react";
import { UseFormWatch } from "react-hook-form";
import { useSetRecoilState } from "recoil";
import { formDirtyStateAtom } from "../../store/common/formDirtyStateStore";

interface UseFormDirtyStateProps {
  watch: UseFormWatch<any>;
  fieldsToWatch?: string[];
  dependencies?: Array<{
    value: any;
    name: string;
  }>;
}

export const useFormDirtyState = ({
  watch,
  fieldsToWatch = [],
  dependencies = [],
}: UseFormDirtyStateProps) => {
  const setIsFormDirty = useSetRecoilState(formDirtyStateAtom);
  const previousValues = useRef<{ [key: string]: string }>({});
  const isFirstRender = useRef(true);
  const isDirty = useRef(false);

  // Form 값 변경 감지
  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name && !isFirstRender.current && fieldsToWatch.includes(name)) {
        console.log(`Form field changed - ${name}:`, {
          value: value[name],
        });
        isDirty.current = true;
        setIsFormDirty(true);
      }
    }, fieldsToWatch);

    return () => {
      subscription.unsubscribe();
      if (isDirty.current) {
        setIsFormDirty(true);
      }
    };
  }, [watch, setIsFormDirty, fieldsToWatch]);

  // dependencies 변경 감지
  useEffect(
    () => {
      if (isFirstRender.current) {
        dependencies.forEach((dep) => {
          previousValues.current[dep.name] = JSON.stringify(dep.value);
        });
        isFirstRender.current = false;
        return;
      }

      dependencies.forEach((dep) => {
        const currentValue = JSON.stringify(dep.value);
        const prevValue = previousValues.current[dep.name];

        if (prevValue !== currentValue) {
          console.log(`${dep.name} changed:`, {
            from: JSON.parse(prevValue || "null"),
            to: dep.value,
          });
          isDirty.current = true;
          setIsFormDirty(true);
        }
        previousValues.current[dep.name] = currentValue;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies.map((dep) => dep.value),
  );

  // 컴포넌트 언마운트나 route 변경 시 초기화
  useEffect(() => {
    return () => {
      isDirty.current = false;
      setIsFormDirty(false);
    };
  }, [setIsFormDirty]);
};
