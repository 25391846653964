import { InternalAxiosRequestConfig } from "axios";
import { enqueueSnackbar } from "notistack";

export default function AxiosRequestHandler(
  config: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const exp = localStorage.getItem("tokenExp");

    if (accessToken) {
      config.headers.Authorization = `${accessToken}`;
    }

    // exp가 있을 때만 만료 체크
    if (exp) {
      const expDate = new Date(exp).getTime();
      const currentTime = new Date().getTime();
      const timeLeft = expDate - currentTime;

      // 남은 시간이 1분 이하면 만료된 것으로 처리
      if (timeLeft <= 60000) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenExp");
        window.location.href = "/";
        enqueueSnackbar("로그인 정보가 만료되었습니다.", { variant: "error" });
      }
    }
  } catch (e) {
    console.error("Error accessing token:", e);
  }

  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
  }

  return config;
}
