import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import ParentsInfoCard from "../../../../common/card/ParentsListCard";
import BottomSheetModal from "../../../../common/modal/BottomSheetModal";
import { parentListType } from "../../../../../types/requestedCourse/requestedCourseTypes";
import CommonModal from "../../../../common/modal/CommonModal";

const ParentListContainer = ({
  parentList,
  setParentList,
}: {
  parentList: parentListType[];
  setParentList: React.Dispatch<React.SetStateAction<parentListType[]>>;
}) => {
  const [modalName, setModalName] = useState<string>("");
  const [initialValues, setInitialValues] = useState<{ [key: string]: string }>(
    {},
  );
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null); // 삭제할 index를 저장할 상태 추가

  const handleEdit = (index: number) => {
    setInitialValues({
      studentName: parentList[index].studentName,
      studentInfo: parentList[index].studentInfo,
      parentMobile: parentList[index].parentMobile,
    });
    setModalName("parentMobile");
    setEditIndex(index);
  };

  const handleDelete = (index: number) => {
    setDeleteIndex(index); // 삭제할 index 저장
    setModalName("deleteParent");
  };

  const handleAdd = () => {
    setInitialValues({
      studentName: "",
      studentInfo: "",
      parentMobile: "",
    });
    setModalName("parentMobile");
    setEditIndex(null);
  };

  const handleConfirm = () => {
    if (deleteIndex !== null) {
      const newParentList: parentListType[] = parentList.filter(
        (_: parentListType, i: number) => i !== deleteIndex,
      );
      setParentList(newParentList);
      setModalName("");
      setDeleteIndex(null);
    }
    enqueueSnackbar("삭제되었습니다.");
  };

  return (
    <div className="flex-wrap">
      <span className="flex-wrap__title">
        학부모 연락처 <em className="color-red">*</em>
      </span>
      <div className="flex-wrap__cont mb5">
        <button
          type="button"
          className="btn btn--small btn--round btn--bordered-primary"
          onClick={handleAdd}
        >
          + 추가
        </button>
      </div>

      <div className="parents-list">
        {parentList?.map((pl, index) => (
          <ParentsInfoCard
            pl={pl}
            key={index}
            onEdit={() => handleEdit(index)}
            onDelete={() => handleDelete(index)}
          />
        ))}
      </div>

      {modalName === "parentMobile" && (
        <BottomSheetModal
          modalName={modalName}
          setModalName={setModalName}
          title={"학부모 연락처 추가"}
          text={[
            "선생님이 과외 요청 승인 시 학부모 휴대폰번호로 과외노트 알림톡이 전송됩니다.",
            "학부모님이 과외노트 APP 로그인 시 해당 과외 정보를 확인할 수 있습니다. (보고서는 확인 불가)",
            "학부모 휴대폰번호는 보고서 전송 시 사용됩니다.",
          ]}
          placeHolder={[
            "*학생 이름 입력",
            "*학년 입력 (예시. 고2)",
            "*학부모 휴대폰번호 입력 (숫자만 입력)",
          ]}
          textLimit={12}
          setParentList={setParentList}
          inputKeys={["studentName", "studentInfo", "parentMobile"]}
          initialValues={initialValues}
          editIndex={editIndex}
        />
      )}
      <CommonModal
        open={modalName === "deleteParent"}
        modalClose={() => {
          setModalName("");
          setDeleteIndex(null);
        }}
        text={"학부모 연락처를 삭제하시겠습니까?"}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default ParentListContainer;
