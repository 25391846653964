import { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import PartnerListContainer from "../PartnerListContainer";
import ButtonDropDown from "../../../common/select/ButtonDropDown";
import usePartnerTutorListRequestInfiniteQuery from "../../../../hooks/partnerTutor/usePartnerTutorRequestListQuery";
import { userAtom } from "../../../../store/common/userStore";

function PartnerTutorRequestedTab() {
  const [partnerTimeFilter, setPartnerTimeFilter] = useState("최신순");
  // const [searchName, setSearchName] = useState("");
  // const [searchQuery, setSearchQuery] = useState(""); // 실제로 검색에 사용되는 상태
  const user = useRecoilValue(userAtom);
  const { data, fetchNextPage, hasNextPage } =
    usePartnerTutorListRequestInfiniteQuery({
      businessCode: String(user.member.id),
      searchTutorName: "",
    });
  const observerElem = useRef(null);

  const onClickFilter = (filter: string) => {
    setPartnerTimeFilter(filter);
  };

  // const onClickSearchName = () => {
  //   setSearchQuery(searchName);
  // };
  //
  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === "Enter") {
  //     setSearchQuery(searchName);
  //   }
  // };

  const sortedData = data
    ? {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          items: page.items.sort((a, b) => {
            const dateA = new Date(a.requestDate ?? 0).getTime();
            const dateB = new Date(b.requestDate ?? 0).getTime();
            return partnerTimeFilter === "과거순"
              ? dateA - dateB
              : dateB - dateA;
          }),
        })),
      }
    : undefined;

  useEffect(() => {
    const currentObserverElem = observerElem.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 },
    );

    if (currentObserverElem) {
      observer.observe(currentObserverElem);
    }

    return () => {
      if (currentObserverElem) {
        observer.unobserve(currentObserverElem);
      }
    };
  }, [hasNextPage, fetchNextPage]);

  const totalNonAcceptItems =
    data?.pages?.reduce((acc, page) => {
      const totalResult = page.totalResult || 0;
      const acceptCount = page.items.filter(
        (item) => item.status === "ACCEPT",
      ).length;
      return acc + (totalResult - acceptCount);
    }, 0) || 0;

  return (
    <>
      {data && data?.pages[0].items.length > 0 ? (
        <>
          {/* <div */}
          {/*   className="field-group mt0 pos-sticky" */}
          {/*   style={{ top: "6.4375rem" }} */}
          {/* > */}
          {/*   <label */}
          {/*     htmlFor="" */}
          {/*     className="field-group__label field-group__label--blind" */}
          {/*   > */}
          {/*     이름 검색 */}
          {/*   </label> */}
          {/*   <div className="field-group__cont"> */}
          {/*     <p role={"presentation"} onClick={onClickSearchName}> */}
          {/*       <i className="icon i-search" /> */}
          {/*     </p> */}
          {/*     <input */}
          {/*       type="text" */}
          {/*       className="field-group__input" */}
          {/*       value={searchName} */}
          {/*       onChange={(e) => { */}
          {/*         setSearchName(e.target.value); */}
          {/*       }} */}
          {/*       onKeyDown={handleKeyDown} */}
          {/*       placeholder="이름 검색" */}
          {/*     /> */}
          {/*   </div> */}
          {/* </div> */}
          <div className="divider" />
          <div className="section pt5">
            <div className="sorting">
              <span className="sorting__title">
                <b>{totalNonAcceptItems}</b> 건
              </span>
              <ButtonDropDown
                buttonText={partnerTimeFilter}
                buttonIcon="i-arrow-down"
                buttonType={"filter"}
                items={[
                  { text: "과거순", onClick: () => onClickFilter("과거순") },
                  { text: "최신순", onClick: () => onClickFilter("최신순") },
                ]}
              />
            </div>

            <PartnerListContainer
              partnerTutorList={sortedData}
              type={"requested"}
            />
            <div ref={observerElem} />
          </div>
        </>
      ) : (
        <div className="page-empty">목록이 없습니다.</div>
      )}
    </>
  );
}

export default PartnerTutorRequestedTab;
