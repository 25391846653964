import React, { ChangeEvent, useRef } from "react";

interface FilePreview {
  name: string;
  ext: string;
  file: File;
}

export function ImageAndDocumentUploadSection({
  title,
  imageSrc,
  setImageSrc,
  documentFile,
  setDocumentFile,
  onImageUpload,
  onDocumentUpload,
}: {
  title: string;
  imageSrc: string | ArrayBuffer | null;
  setImageSrc: React.Dispatch<
    React.SetStateAction<string | ArrayBuffer | null>
  >;
  documentFile: FilePreview | null;
  setDocumentFile: React.Dispatch<React.SetStateAction<FilePreview | null>>;
  onImageUpload: (e: ChangeEvent<HTMLInputElement>) => void;
  onDocumentUpload: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
  const imageInputRef = useRef<HTMLInputElement>(null);
  const documentInputRef = useRef<HTMLInputElement>(null);

  const handleImageDelete = () => {
    setImageSrc("");
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }
  };

  const handleDocumentDelete = () => {
    setDocumentFile(null);
    if (documentInputRef.current) {
      documentInputRef.current.value = "";
    }
  };

  return (
    <div className="field-group">
      <div className="typo-medium mt6">{title}</div>
      <div className="field-group__cont field-group__cont--no-style">
        <div className="field-group__check-characters text-left mt5">
          <span className="color-black">사진</span> <em>{imageSrc ? 1 : 0}</em>{" "}
          / 1
        </div>
        <div className="regi-image">
          <div className="regi-image__list">
            <div className="regi-image__item">
              <label className="regi-image__label">
                <i className="icon i-add-circle" />
                <input
                  ref={imageInputRef}
                  className="regi-image__input"
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={onImageUpload}
                />
              </label>
            </div>
            {imageSrc && (
              <div className="regi-image__item">
                <span
                  role="presentation"
                  className="btn-delete"
                  onClick={handleImageDelete}
                >
                  <i className="icon i-close-white" />
                </span>
                <img
                  className="thumb"
                  src={imageSrc?.toString()}
                  alt="profile"
                />
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="field-group__cont field-group__cont--no-style">
        <div className="field-group__check-characters text-left mt5">
          <span className="color-black">문서</span>{" "}
          <em>{documentFile ? 1 : 0}</em> / 1
        </div>
        <div className="file-upload">
          <div id="file-preview" className="file-upload__preview">
            {documentFile && (
              <>
                <div>
                  <span className="name">{documentFile?.name}</span>
                  <span className="ext">{documentFile?.ext}</span>
                </div>
                <button
                  type="button"
                  className="btn-delete"
                  onClick={handleDocumentDelete}
                >
                  <i className="icon i-close-white" />
                </button>
              </>
            )}
          </div>
          <label form="file-upload-input" className="file-upload__label">
            + 추가하기
            <input
              ref={documentInputRef}
              type="file"
              id="file-upload-input"
              className="file-upload__input"
              onChange={onDocumentUpload}
              accept=".pdf"
            />
          </label>
        </div>
      </div>
    </div>
  );
}
