import { AxiosApiWrapper } from "../abstract/axios-api-wrapper.abstract";
import { BoardInfoType, BoardListType } from "../../types/myInfo/boardTypes";

class BoardApi extends AxiosApiWrapper {
  protected endPoint!: string;

  public fetchQuestionList(
    data: Record<string, unknown>,
  ): Promise<BoardListType> {
    this.endPoint = `api/v1/boards/qna`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchFreqQuestionList(
    data: Record<string, unknown>,
  ): Promise<BoardListType> {
    this.endPoint = `api/v1/boards/faq`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchNoticeList(
    data: Record<string, unknown>,
  ): Promise<BoardListType> {
    this.endPoint = `api/v1/boards/notice`;
    try {
      return this.post(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchBoardInfo(endPoint: string): Promise<BoardInfoType> {
    this.endPoint = `api/v1/boards/${endPoint}`;
    try {
      return this.get();
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  // 문의하기 추가
  public fetchAddBoard(data: any) {
    this.endPoint = `api/v1/boards`;
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      return this.post(data, config);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  public fetchDeleteBoard(data: any) {
    this.endPoint = `api/v1/boards`;
    try {
      return this.delete(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
}

export default new BoardApi();
