import { useState } from "react";
import { ProfileListType } from "../../../../types/profile/profileListTypes";
import { extractYoutubeId } from "../../../../util/util";
import { EnlargeBox } from "../../../common/modal/EnlargeBox";

function ImageAndVideoViewSection({
  profileData,
}: {
  profileData?: ProfileListType;
}) {
  const [modalName, setModalName] = useState<string>("");
  const [selectedImg, setSelectedImg] = useState<string | null>(null);
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [selectedYoutube, setSelectedYoutube] = useState<string | null>(null);

  const handleMediaClick = (linkUrl: string) => {
    const youtubeId = extractYoutubeId(linkUrl);
    if (youtubeId) {
      setModalName("youtube");
      setSelectedYoutube(linkUrl);
    } else if (linkUrl.endsWith(".mp4")) {
      setModalName("video");
      setSelectedVideo(linkUrl);
    } else {
      setModalName("img");
      setSelectedImg(linkUrl);
    }
  };

  return (
    <>
      {profileData?.imageLinks?.map((item: any, index) => {
        const { linkUrl } = item;
        const youtubeId = extractYoutubeId(linkUrl);
        const isMp4 = linkUrl.endsWith(".mp4");

        return (
          <div
            role="presentation"
            onClick={() => handleMediaClick(linkUrl)}
            className="regi-image__item"
            key={`regi-image__item-${index}`}
          >
            {youtubeId ? (
              <img
                className="thumb"
                src={`https://img.youtube.com/vi/${youtubeId}/0.jpg`}
                alt="YouTube Thumbnail"
              />
            ) : isMp4 ? (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video className="thumb" controls>
                <source src={linkUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                className="thumb"
                src={linkUrl.toString()}
                alt="contentImage"
              />
            )}
          </div>
        );
      })}

      <EnlargeBox
        type={"img"}
        open={modalName === "img"}
        close={() => setModalName("")}
        value={selectedImg}
      />

      <EnlargeBox
        type={"video"}
        open={modalName === "video"}
        close={() => setModalName("")}
        value={selectedVideo}
      />

      <EnlargeBox
        type={"youtube"}
        open={modalName === "youtube"}
        close={() => setModalName("")}
        value={selectedYoutube}
      />
    </>
  );
}

export default ImageAndVideoViewSection;
