import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import AuthApi from "../../services/auth/AuthApi";

const useCheckPasswordMutation = () => {
  const navigate = useNavigate();
  return useMutation(
    (params: { password: string; memberType: string }) => {
      return AuthApi.fetchCheckPassword({ ...params });
    },
    {
      onSuccess: (data) => {
        console.log("Mutation successful:", data);
        navigate("/my-info/management");
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
    },
  );
};

export default useCheckPasswordMutation;
