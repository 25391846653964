import { useRecoilValue } from "recoil";

import BoardCard from "../BoardCard";
import { userAtom } from "../../../../../store/common/userStore";
import useNoticeListQuery from "../../../../../hooks/myInfo/board/useNoticeListQuery";
import { BoardType } from "../../../../../types/myInfo/boardTypes";

function QuestionContainer() {
  const user = useRecoilValue(userAtom);
  const { data: noticeList } = useNoticeListQuery({
    memberCode: String(user.member.id),
    memberTypes: user.member.memberType,
    searchType: "",
    searchText: "",
  });

  return (
    <>
      {noticeList?.items && noticeList?.items.length > 0 ? (
        <main className="content">
          <div className="section">
            <div className="board-list">
              <ul className="board-list__group">
                {noticeList.items.map((item: BoardType, index: number) => (
                  <BoardCard
                    type={"notice"}
                    boardData={item}
                    key={`board-list-${index}`}
                  />
                ))}
              </ul>
            </div>
          </div>
        </main>
      ) : (
        <main className="content">
          <div className="page-empty">목록이 없습니다.</div>
        </main>
      )}
    </>
  );
}

export default QuestionContainer;
