import { useEffect } from "react";
import { profileGroups } from "./ProfileGroups";
import ProfileInfoList from "./ProfileInfoList";
import { useProfilePreviewQuery } from "../../../../hooks/profile/preview/useProfilePreviewQuery";
import defaultImage from "../../../../assets/img/profile_default.jpg";
import { calculateAge, translateValue } from "../../../../util/util";
import { genderConfig } from "../../../../config/config";

function ProfileInfoPreview({
  propsId,
  isExternal,
  clickClose,
}: {
  propsId?: string;
  isExternal?: boolean;
  clickClose?: () => void;
}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { data: profilePreview } = useProfilePreviewQuery(String(propsId));

  const imageSrc = profilePreview?.profileImage
    ? profilePreview?.profileImage.toString()
    : defaultImage;
  return (
    <form
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {!isExternal && (
        <div className="modal-dialog__header">
          <span className="modal-dialog__title">선생님 프로필</span>
          <button
            onClick={clickClose}
            type="button"
            className="modal-dialog__close"
          >
            <i className="icon i-close" />
          </button>
        </div>
      )}

      <div className={`${!isExternal && "modal-dialog__body"}`}>
        <main className="content profile pb0">
          <div className="section pb8">
            <div className="flex-box flex-box--direction-column flex-box--justify-center flex-box--items-center">
              <div className="profile-photo">
                <div className="profile-photo__image-preview">
                  <img
                    className="profile-photo__img"
                    src={imageSrc}
                    alt="Profile"
                  />
                </div>
              </div>
              <div className="flex-box flex-box--direction-column flex-box--items-center text-center">
                <div className="badge">본인인증 완료</div>
                <span className="typo-large strong my1">
                  {profilePreview?.name} 선생님
                </span>
                {profilePreview?.gender && (
                  <span className="typo-small">
                    <>
                      {translateValue(profilePreview?.gender, genderConfig)} /{" "}
                      {calculateAge(profilePreview?.birthDate)}세
                    </>
                  </span>
                )}
              </div>
              <div className="text-center pt6">
                <p className="typo-small">{profilePreview?.introduce}</p>
              </div>
            </div>
          </div>
          <div className="divider" />

          <div className="section">
            {profileGroups.map(({ label, noPreview }, index) => (
              <ProfileInfoList
                key={`profileInfoList-preview-${index}`}
                profileData={profilePreview}
                label={label}
                type={"preview"}
                noPreview={noPreview}
              />
            ))}
            <div className={"typo-small2 color-blue pt5 pb5 py3"}>
              *프로필에는 개인정보가 포함되어 있으므로 무단 전재 및 재배포를
              금지합니다.
            </div>
          </div>
        </main>
      </div>
    </form>
  );
}

export default ProfileInfoPreview;
