import { useRecoilValue } from "recoil";
import { useState } from "react";
import { ProfileInfoListProps } from "../../../../types/profile/profileListTypes";
import { configAtom } from "../../../../store/common/configStore";
import ProfileDocumentCheckContainer from "./editContainers/ProfileDocumentCheckContainer";
import CommonModalDialog from "../../../common/modal/CommonModalDialog";
import { translateValue } from "../../../../util/util";
import ImageAndVideoViewSection from "../section/ImageAndVideoViewSection";

function ProfileInfoList({
  label,
  setModalName,
  required,
  noPreview,
  noEdit,
  profileData,
  type,
}: ProfileInfoListProps) {
  const config = useRecoilValue(configAtom);
  const [documentCheckModalName, setDocumentCheckModalName] = useState("");

  const renderContent = () => {
    if (!profileData) {
      return null;
    }

    switch (label) {
      case "학력정보":
        return !(
          profileData?.universityName ||
          profileData?.majorName ||
          profileData?.schoolStatus
        ) ? null : (
          <>
            <div>
              <p>{profileData?.universityName}</p>
              <p>
                {profileData?.classNumber && (
                  <>
                    {profileData?.majorName} {profileData?.classNumber}학번
                  </>
                )}
              </p>
              <p>
                {translateValue(profileData?.schoolStatus, config.schoolStatus)}
              </p>
            </div>
            {(type === "copy" || type === "preview") && (
              <div className="profile-group__certify">
                {profileData?.diplomaCertFlag === "Y" && (
                  <div className="chips">
                    <span className="chips__item">재학/졸업증명서 인증 V</span>
                  </div>
                )}
                {type !== "preview" && (
                  <button
                    className="btn btn--small btn--gray"
                    onClick={() => setDocumentCheckModalName("education")}
                  >
                    인증서류 확인
                  </button>
                )}
              </div>
            )}
          </>
        );
      case "사진 및 동영상":
        return !profileData ? null : (
          <div className="regi-image">
            <div className="regi-image__list">
              <ImageAndVideoViewSection profileData={profileData} />
            </div>
          </div>
        );
      case "지도과목":
        return !profileData?.subject ? null : <p>{profileData.subject}</p>;
      case "한줄소개":
        return !profileData?.introduce || type === "preview" ? null : (
          <p>{profileData.introduce}</p>
        );
      case "경력":
        return !profileData?.career ? null : <p>{profileData.career}</p>;
      case "수업스타일 및 수업 특장점":
        return !profileData?.classStyle ? null : (
          <p>{profileData.classStyle}</p>
        );
      case "성적 향상 사례":
        return !profileData?.improveGrades ? null : (
          <p>{profileData.improveGrades}</p>
        );
      case "수업 선호 학년":
        return !profileData?.preferGrade ? null : (
          <p>{profileData.preferGrade}</p>
        );
      case "과외 방식(대면과외/화상과외)":
        return !profileData?.classType ? null : (
          <div>
            {(profileData.classType === "FACE" ||
              profileData.classType === "ALL") && (
              <div
                className={`${type === "copy" && "pt2"}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>{translateValue("FACE", config.classType)}과외</p>
                {(type === "copy" || type === "preview") && (
                  <div className="profile-group__certify">
                    {profileData?.tutoringCertFlag === "Y" && (
                      <div className="chips">
                        <span className="chips__item">
                          개인과외교습자 신고증명서 인증 V
                        </span>
                      </div>
                    )}
                    {type !== "preview" && (
                      <button
                        className="btn btn--small btn--gray"
                        onClick={() =>
                          setDocumentCheckModalName("classTypeFace")
                        }
                      >
                        인증서류 확인
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
            {(profileData.classType === "ONLINE" ||
              profileData.classType === "ALL") && (
              <div
                className={`${type === "copy" && "pt4"}`}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>{translateValue("ONLINE", config.classType)}과외</p>
                {(type === "copy" || type === "preview") && (
                  <div className="profile-group__certify">
                    {profileData?.mailOrderSalesCertFlag === "Y" && (
                      <div className="chips">
                        <span className="chips__item">
                          통신판매업 신고증 인증 V
                        </span>
                      </div>
                    )}
                    {type !== "preview" && (
                      <button
                        className="btn btn--small btn--gray"
                        onClick={() =>
                          setDocumentCheckModalName("classTypeOnline")
                        }
                      >
                        인증서류 확인
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      case "수업 경험 학교":
        return !profileData?.schoolExperience ? null : (
          <p>{profileData.schoolExperience}</p>
        );
      case "시범 과외(유료/무료)":
        return !(
          profileData?.demoClassType || profileData?.demoClassDesc
        ) ? null : (
          <div>
            <p>
              {translateValue(profileData?.demoClassType, config.demoClassType)}
            </p>
            <p>{profileData?.demoClassDesc}</p>
          </div>
        );
      case "주 사용 교재":
        return !profileData?.teachingMaterial ? null : (
          <p>{profileData.teachingMaterial}</p>
        );
      case "대학 합격 및 중/고등학교 정보":
        return !(
          profileData?.admissionSelection ||
          profileData?.highSchool ||
          profileData?.middleSchool
        ) ? null : (
          <div>
            {profileData?.admissionSelection && (
              <p>
                <b>합격전형</b>{" "}
                {translateValue(
                  profileData.admissionSelection,
                  config?.admissionSelectionType,
                )}{" "}
                |{" "}
                {translateValue(
                  profileData.admissionProcess,
                  config?.admissionProcessType,
                )}
              </p>
            )}
            {profileData?.highSchool && (
              <p>
                <b>고등학교</b> {profileData.highSchool}
              </p>
            )}
            {profileData?.middleSchool && (
              <p>
                <b>중학교</b> {profileData.middleSchool}
              </p>
            )}
          </div>
        );
      case "대면과외 가능지역":
        return !profileData?.faceToFaceArea ? null : (
          <p>{profileData.faceToFaceArea}</p>
        );
      case "거주지":
        return !profileData?.address ? null : <p>{profileData.address}</p>;
      case "수업 가능 일정":
        return !profileData?.possibleSchedule ? null : (
          <p>{profileData.possibleSchedule}</p>
        );
      case "평균 수업료":
        return !profileData?.averagePay ? null : (
          <p>{profileData.averagePay}</p>
        );
      case "휴대폰 외 연락처":
        return !profileData?.contactAddress ? null : (
          <p>{profileData.contactAddress}</p>
        );
      default:
        return null;
    }
  };

  // 미리보기 모드에서 한줄소개 숨기기 또는 데이터가 없는 경우 숨기기
  // or 사업자에서는 미리보기가 아니여도 값이 없으면(선생님이 작성하지 않아서) 미노출
  if (
    (type === "preview" && (label === "한줄소개" || noPreview)) ||
    !renderContent()
  ) {
    return null;
  }

  return (
    <>
      <div className="profile-group">
        <div className="profile-group__head">
          <div className="profile-group__label">
            {label} {noPreview && <span className="badge">학부모미노출</span>}
          </div>
          {type !== "preview" && type === "copy" && !noEdit && (
            <div className="profile-group__btn">
              <button
                className="pf-modify"
                onClick={() => setModalName?.(label)}
              >
                {required && <em>*</em>} 수정
              </button>
            </div>
          )}
        </div>
        <div className="profile-group__cont">
          {label === "사진 및 동영상" ? (
            renderContent()
          ) : (
            <div className="profile-group__text">{renderContent()}</div>
          )}
        </div>
      </div>

      {documentCheckModalName && (
        <CommonModalDialog setModalName={setDocumentCheckModalName}>
          {({ clickClose }) => (
            <ProfileDocumentCheckContainer
              modalName={documentCheckModalName}
              profileData={profileData}
              clickClose={clickClose}
            />
          )}
        </CommonModalDialog>
      )}
    </>
  );
}

export default ProfileInfoList;
