import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/ko";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useState } from "react";
import { PickersCalendarHeaderProps } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center",
});

dayjs.locale("ko");

interface CustomCalendarHeaderProps extends PickersCalendarHeaderProps<Dayjs> {
  onTodayClick: () => void;
}

function CustomCalendarHeader(props: CustomCalendarHeaderProps) {
  const { currentMonth, onMonthChange, onTodayClick } = props;

  const selectNextMonth = () =>
    onMonthChange(currentMonth.add(1, "month"), "left");
  const selectPreviousMonth = () =>
    onMonthChange(currentMonth.subtract(1, "month"), "right");

  return (
    <CustomCalendarHeaderRoot>
      <Stack spacing={1} direction="row">
        <button onClick={onTodayClick}>today</button>
        <IconButton onClick={selectPreviousMonth} title="Previous month">
          <ChevronLeft />
        </IconButton>
      </Stack>
      <Typography variant="body2">
        {currentMonth.format("YYYY년 MMMM")}
      </Typography>
      <Stack spacing={1} direction="row">
        <IconButton onClick={selectNextMonth} title="Next month">
          <ChevronRight />
        </IconButton>
      </Stack>
    </CustomCalendarHeaderRoot>
  );
}

interface CommonDatePickerProps {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  width: number;
  disabled?: boolean;
}

export default function CommonDatePicker({
  value,
  onChange,
  width,
  disabled,
}: CommonDatePickerProps) {
  const [open, setOpen] = useState(false);

  const handleTodayClick = () => {
    const today = dayjs();
    onChange(today);
  };

  const shouldDisableDate = (date: Dayjs) => {
    return date.isBefore(dayjs(), "day") || date.isSame(dayjs(), "day");
  };

  return (
    <div
      role={"presentation"}
      onClick={() => {
        if (!open && !disabled) {
          setOpen(true);
        }
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
        <MobileDatePicker
          disabled={disabled}
          value={value}
          onChange={(newValue) => {
            onChange(newValue);
            setOpen(false); // 날짜 선택시 자동으로 닫히도록
          }}
          shouldDisableDate={shouldDisableDate}
          slotProps={{
            textField: {
              sx: () => ({
                width: `${width}%`,
                ...(disabled && {
                  borderRadius: "4px",
                  backgroundColor: "#efefef",
                  height: "50px",
                  paddingTop: "5px",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none",
                    },
                  },
                }),
              }),
              placeholder: "연도-월-일",
            },
            toolbar: {
              hidden: true,
            },
            dialog: {
              sx: {
                "& .MuiDialogActions-root": {
                  display: "none",
                },
                // 달력 하단 여백 조정
                "& .MuiPickersLayout-contentWrapper": {
                  marginBottom: 0,
                },
              },
            },
          }}
          slots={{
            calendarHeader: (props) => (
              <CustomCalendarHeader
                {...props}
                onTodayClick={handleTodayClick}
              />
            ),
          }}
          open={open}
          onClose={() => setOpen(false)}
          format="YYYY.MM.DD(ddd)"
        />
      </LocalizationProvider>
    </div>
  );
}
